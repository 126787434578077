import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { Component } from "react";
import "../assets/custom.css";

import Header from "./Common/Includes/Header";
import Footer from "./Common/Includes/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Placement from "./Pages/Placement";
import Testimonial from "./Pages/Testimonial";
import Contact from "./Pages/Contact";

import FeaturedCourse from "./Pages/FeaturedCourse";
import TransitionCourse from "./Pages/TransitionCourse";
import OnlineCourse from "./Pages/OnlineCourse";

import CLang from "./Pages/C-Lang";
import Python from "./Pages/Python";
import Oracle from "./Pages/Oracle";
import Selenium from "./Pages/Selenium";
import NodeJs from "./Pages/NodeJS";
import ReactJs from "./Pages/ReactJS";
import Dotnet from "./Pages/Dotnet";
import InformicaTrain from "./Pages/InformicaTraining";
import FullStack from "./Pages/FullStack";
import SoftwareTesting from "./Pages/SoftwareTesting";
import WebDesigning from "./Pages/WebDesigning";
import Java from "./Pages/Java";
import RProgramming from "./Pages/RProgramming";
import PowerBI from "./Pages/PowerBI";
import MicrosoftAzure from "./Pages/MicrosoftAzure";
import Devops from "./Pages/DevOps";
import DataScience from "./Pages/DataScience";
import CloudComputing from "./Pages/CloudComputing";
import Digitalmarketing from "./Pages/DigitalMarketing";
import AWS from "./Pages/AWS";
import Iot from "./Pages/IOT";
import Blockchain from "./Pages/Blockchain";
import AI from "./Pages/AI";
import Cybersecurity from "./Pages/CyberSecurity";
import Web3 from "./Pages/Web3";
class Layout extends Component {
  state = {};
  render() {
    return (
      <div>
        <BrowserRouter>
          <Header />
          <main role="main" className="flex-shrink-0">
            <Routes basename={"/"}>
              <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
              <Route
                path={`${process.env.PUBLIC_URL}/about`}
                element={<About />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/placement`}
                element={<Placement />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/contact`}
                element={<Contact />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/testimonial`}
                element={<Testimonial />}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/featuredCourse`}
                element={<FeaturedCourse />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/transitionCourse`}
                element={<TransitionCourse />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/onlineCourse`}
                element={<OnlineCourse />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/cLang`}
                element={<CLang />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/python`}
                element={<Python />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/oracle`}
                element={<Oracle />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/selenium`}
                element={<Selenium />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/nodejs`}
                element={<NodeJs />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/reactjs`}
                element={<ReactJs />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/dotnet`}
                element={<Dotnet />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/informtrain`}
                element={<InformicaTrain />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/fullstack`}
                element={<FullStack />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/softwaretesting`}
                element={<SoftwareTesting />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/webdesigning`}
                element={<WebDesigning />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/java`}
                element={<Java />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/rprogramming`}
                element={<RProgramming />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/powerbi`}
                element={<PowerBI />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/microsoftazure`}
                element={<MicrosoftAzure />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/devops`}
                element={<Devops />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/datascience`}
                element={<DataScience />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/cloudcomputing`}
                element={<CloudComputing />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/digitalmarketing`}
                element={<Digitalmarketing />}
              />
              <Route path={`${process.env.PUBLIC_URL}/aws`} element={<AWS />} />
              <Route path={`${process.env.PUBLIC_URL}/iot`} element={<Iot />} />
              <Route
                path={`${process.env.PUBLIC_URL}/blockchain`}
                element={<Blockchain />}
              />
              <Route path={`${process.env.PUBLIC_URL}/ai`} element={<AI />} />
              <Route
                path={`${process.env.PUBLIC_URL}/cybersecurity`}
                element={<Cybersecurity />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/web3`}
                element={<Web3 />}
              />
            </Routes>
          </main>

          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

export default Layout;
