import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import NodeJS from "../../assets/img/courses/nodejs.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class Nodejs extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Node.js</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Node.js?</h3>
                  <p>
                    Node.js is an open-source server environment. Node.js is
                    cross-platform and runs on Windows, Linux, Unix, and macOS.
                    Node.js is a back-end JavaScript runtime environment.
                    Node.js runs on the V8 JavaScript Engine and executes
                    JavaScript code outside a web browser.
                  </p>
                  <p>
                    Node.js lets developers use JavaScript to write command line
                    tools and for server-side scripting. The functionality of
                    running scripts server-side produces dynamic web page
                    content before the page is sent to the user's web browser.
                  </p>
                  <h3>All Node.Js courses</h3>
                  <p>
                    A Node.js course is a great way to enter into web and mobile
                    development. It can be used to build apps at any scale and
                    is widely used to create applications of every sort. There
                    is a great need for developers with working knowledge of
                    Nodel.js at companies everywhere.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img className="img-fluid" src={NodeJS} alt="First slide" />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    What are the advantages of Node.js?
                  </h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Node.Js is open-source, meaning it’s a free code for all
                    developers. On top of that, it also means that there is a
                    thriving community of Node.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Js users and programmers that all add to the knowledge base.
                    Many are happy to share their flavor of the code with other
                    developers, and collectively, the Node.Js environment
                    continues to be enhanced. Because Node.Js uses JavaScript,
                    there is a large pool of developers that understand and can
                    code in the environment.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    It’s also a relatively simple environment for new team
                    members to learn, making it an efficient choice for
                    development teams with people that need training.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Node.Js was developed on Push technology instead of web
                    sockets, which is why it’s the preferred choice for
                    real-time communication applications and programs that need
                    to be highly scalable.
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default Nodejs;
