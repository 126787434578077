import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import Individual from "../../assets/img/courseImgC.jpg";
import Code from "../../assets/img/courses/code.jpeg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class CLang extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">C and C ++</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is C and C++ Programming Language?</h3>
                  <p>
                    C is a general-purpose programming language that is
                    extremely popular, simple, and flexible to use.
                  </p>
                  <p>
                    It is a structured programming language that is
                    machine-independent and extensively used to write various
                    applications, Operating Systems like Windows, and many other
                    complex programs like Oracle database, Git, Python
                    interpreter, and more.
                  </p>
                  <p>
                    C++ was designed with systems programming and embedded,
                    resource-constrained software and large systems in mind,
                    with performance, efficiency, and flexibility of use as its
                    design highlights.
                  </p>
                  <p>
                    The language has expanded significantly over time, and
                    modern C++ now has object-oriented, generic, and functional
                    features in addition to facilities for low-level memory
                    manipulation.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-2 text-start"></div>
            <Row>
              <Col sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img className="img-fluid" src={Code} alt="First slide" />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <p>
                    Skilfully crafted C and C++ Course Curriculum to cater to
                    the global requirements of the Software Development
                    industry.
                  </p>
                  <p>
                    Courseware that is tailored by Industrial Experts to obtain
                    a comprehensive knowledge of the Fundamentals of C and C++,
                    Data Structures, Algorithms, Loops & Conditional Statements,
                    Flow Charts, and Operators.
                  </p>
                  <p>
                    Immersive C and C++ Training to get you acquainted with
                    Writing & Debugging codes in C and C++.
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default CLang;
