import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/img/logo-sw.png";
import { Container, Row, Col } from "react-bootstrap";
import * as Bi from "react-icons/bs";
import * as Fa from "react-icons/fa";
import Home from "../../Pages/Home";
import About from "../../Pages/About";
import FeaturedCourse from "../../Pages/FeaturedCourse";
import Linkedin from "../../../assets/img/linkedin.png";
import Facebook from "../../../assets/img/facebook.png";
import Twitter from "../../../assets/img/twitter.png";
import Youtube from "../../../assets/img/youtube.png";

class Footer extends Component {
  /* constructor(props) {
        super(props);
    } */
  state = {};
  render() {
    return (
      <footer className="">
        <Container className="footer">
          <Row>
            <Col sm={12} md={12} lg={4}>
              <img
                src={Logo}
                className="d-inline-block align-top logo"
                alt="logo"
                title="Grabbtech"
              />
              <p>
                GRABB provides world-class facilities and experienced faculty to
                our students. Our software programs are designed to meet the
                ever-changing needs of the IT industry.
              </p>
              <ul className="social_media">
                <li>
                  <a href="#" target="_blank">
                    <img
                      src={Linkedin}
                      className=""
                      alt="logo"
                      title="Grabbtech"
                    />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img
                      src={Facebook}
                      className=""
                      alt="logo"
                      title="Grabbtech"
                    />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img
                      src={Twitter}
                      className=""
                      alt="logo"
                      title="Grabbtech"
                    />
                  </a>
                </li>
                <li className="ms-1">
                  <a href="#" target="_blank">
                    <div className="yt-square"></div>
                  </a>
                </li>
              </ul>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <h4>Quick Access</h4>
              <Row>
                <Col sm={6}>
                  <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    className="d-block link"
                  >
                    {" "}
                    Home
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/about`}
                    className="d-block link"
                  >
                    {" "}
                    About
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/featuredcourse`}
                    className="d-block link"
                  >
                    {" "}
                    Featured Courses
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/transitioncourse`}
                    className="d-block link"
                  >
                    {" "}
                    Transition Courses
                  </Link>
                </Col>
                <Col sm={6}>
                  <Link
                    to={`${process.env.PUBLIC_URL}/onlinecourse`}
                    className="d-block link"
                  >
                    {" "}
                    Online Courses
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/placement`}
                    className="d-block link"
                  >
                    {" "}
                    Placement
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/testimonial`}
                    className="d-block link"
                  >
                    {" "}
                    Testimonials
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/contact`}
                    className="d-block link"
                  >
                    {" "}
                    Contact Us
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <h4>Contact Us</h4>
              <h6>Address</h6>
              <p>
                TA1, Sree Ragaventhara Nestle, Kumbakonathan Salai, Old Kallanai
                Road, Thiruvanakovil, Trichy-620 005.
              </p>
              <Row>
                {/* <Col sm={6}>
                  <h6>Phone No</h6>
                  <p>+(044) 4380 5522</p>
                </Col> */}
                <Col sm={6}>
                  <h6>Email</h6>
                  <p>info@grabbtech.com</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container className="text-center copyright">
          <Row>
            <Col>
              <p className="m-0 text-white py-2">
                Copyright © 2022. GRABB Tech All rights reserved | Designed &
                Developed by{" "}
                <a href="https://tagtes.com/" target="_blank">
                  TAGTES
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
