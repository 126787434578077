import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Card, Button, Col, Row } from "react-bootstrap";
import * as Bi from "react-icons/bs";
import FeatureCourse from "../Data/FeaturedCourse.json";
import Gototop from "../Common/Includes/Top";
import { Animated } from "react-animated-css";

class Featured extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="innerBanner featureBg">
          <Container>
            <div>
              <h3 className="text-white">
                {/* Have a look latest Course here around you */}
                Featured Courses
              </h3>
            </div>
          </Container>
        </section>

        <section className="">
          <Container className="coursedetail">
            <Row>
              {FeatureCourse.map((course, index) => {
                return (
                  <Col className="mb-4" sm={12} md={6} lg={3}>
                    <Animated
                      animationIn="fadeIn"
                      animationInDelay={500}
                      animationInDuration={500}
                      isVisible={true}
                    >
                      <Link
                        to={`${process.env.PUBLIC_URL}${course.btn}`}
                        className="link coursedetCard"
                      >
                        <div className="img">
                          <img
                            className=""
                            src={`${process.env.PUBLIC_URL}/img/featured/${course.img}`}
                            alt={course.coursetitle}
                            width="15"
                          />
                        </div>
                        <div className="detail">
                          <span>{course.detail}</span>
                        </div>
                        <div className="content text-center py-3">
                          <h5 className="ctitle">{course.coursetitle}</h5>
                          <Link
                            to={`${process.env.PUBLIC_URL}${course.btn}`}
                            className="link"
                          >
                            {" "}
                            Know more{" "}
                            <Bi.BsArrowRightShort className="more-icon" />
                            <i class="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </Link>
                    </Animated>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default Featured;
