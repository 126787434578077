import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Carousel,
  Container,
  Button,
  Col,
  Row,
  Card,
  Figure,
} from "react-bootstrap";
import { Animated } from "react-animated-css";
import { BsCheck2, BsCheckLg } from "react-icons/bs";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import $ from "jquery";
import WeBest from "../Data/Webest.json";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import aboutimg from "../../assets/img/abouthome.jpg";
import featureImg from "../../assets/img/featuredCourse.jpg";
import transitionImg from "../../assets/img/transition.jpg";
import onlineImg from "../../assets/img/online.jpg";
import SlideA from "../../assets/img/slide-A.jpg";
import SlideB from "../../assets/img/slide-B.jpg";
import Profile from "../../assets/img/profile.png";
import Clang from "../../assets/img/c++.jpg";
import Python from "../../assets/img/python.jpg";
import Oracle from "../../assets/img/oracle.jpg";
import Selenium from "../../assets/img/selenium.jpg";
import SoftwareTesting from "../../assets/img/softwaretesting.jpg";
import Webdesign from "../../assets/img/webdesign.jpg";
import Java from "../../assets/img/java.jpg";
import DigitalMarketing from "../../assets/img/digitalmarketing.jpg";
import Blockchain from "../../assets/img/blockchain.jpg";
import AI from "../../assets/img/ai.jpg";
import CyberSecurity from "../../assets/img/cybersecurity.jpg";
import Web3 from "../../assets/img/web3.0.jpg";
import CTA from "../../assets/img/cta.png";
import Webest from "../../assets/img/webest.jpg";
import Avatar1 from "../../assets/img/avatar1.jpg";
import Avatar2 from "../../assets/img/avatar2.jpg";
import Avatar3 from "../../assets/img/avatar3.jpg";

import About from "./About";
import FeaturedCourse from "./FeaturedCourse";
import Gototop from "../Common/Includes/Top";

const werebest = {
  animateOut: "slideOutDown",
  animateIn: "zoomIn",
  loop: true,
  margin: 0,
  dots: false,
  nav: true,
  smartSpeed: 300,
  autoplay: 7000,
  navText: [
    '<span className="arrow-left"></span>',
    '<span className="arrow-right"></span>',
  ],
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    800: {
      items: 1,
    },
    1024: {
      items: 3,
    },
  },
};

const testimony = {
  loop: true,
  margin: 0,
  merge: false,
  center: false,
  callbacks: true,
  autoplay: true,
  smartSpeed: 500,
  autoplay: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const coursebtn = {
  loop: true,
  margin: 0,
  nav: false,
  dots: false,
  merge: false,
  center: false,
  callbacks: true,
  autoplay: true,
  smartSpeed: 500,
  autoplay: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const course = {
  loop: true,
  margin: 0,
  nav: false,
  dots: false,
  merge: false,
  center: false,
  callbacks: true,
  autoplay: true,
  smartSpeed: 700,
  autoplay: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const testimonial = {
  animateOut: "slideOutDown",
  animateIn: "zoomIn",
  loop: true,
  margin: 0,
  dots: false,
  nav: true,
  smartSpeed: 3000,
  autoplay: 4000,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    800: {
      items: 1,
    },
    1024: {
      items: 1,
    },
  },
};

class Home extends Component {
  /* constructor(props) {
        super(props);
    } */
  state = {};
  render() {
    return (
      <>
        <section className="p-0">
          <Carousel fade className="ban">
            <Carousel.Item className="slide1">
              <Carousel.Caption>
                <Row>
                  <Col sm={12} md={5} lg={5} className="my-auto text">
                    <Col
                      sm={12}
                      md={10}
                      lg={10}
                      className=""
                      data-wow-duration="2s"
                      data-wow-iteration="10"
                    >
                      <Animated
                        animationIn="fadeInLeft"
                        animationInDelay={500}
                        animationInDuration={500}
                        isVisible={true}
                      >
                        <h1>Elevate Your Career To Grow Higher</h1>
                        <p>Learn technology from the best.</p>
                        {/* <div className="cus-button">
                          <Link
                            to={`${process.env.PUBLIC_URL}/contact`}
                            className="cusbtn text-white"
                          >
                            {" "}
                            Enquiry
                          </Link>
                          <div className="cusbtnOver"></div>
                        </div> */}
                      </Animated>
                    </Col>
                  </Col>
                  <Col sm={12} md={5} lg={5} className="my-auto img">
                    <Col sm={12} md={10} lg={10}>
                      <Animated
                        animationIn="fadeInRight"
                        animationInDelay={500}
                        animationInDuration={500}
                        isVisible={true}
                      >
                        <img
                          className="img-fluid"
                          src={SlideA}
                          alt="First slide"
                          width="15"
                        />
                      </Animated>
                    </Col>
                  </Col>
                </Row>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="slide2">
              <Carousel.Caption>
                <Row>
                  <Col sm={12} md={5} lg={5} className="my-auto img">
                    <Col sm={12} md={10} lg={10}>
                      <Animated
                        animationIn="fadeInLeft"
                        animationInDelay={500}
                        animationInDuration={500}
                        isVisible={true}
                      >
                        <img
                          className="img-fluid"
                          src={SlideB}
                          alt="First slide"
                          width="15"
                        />
                      </Animated>
                    </Col>
                  </Col>
                  <Col sm={12} md={5} lg={5} className="my-auto text">
                    <Col sm={12} md={10} lg={10}>
                      <Animated
                        animationIn="fadeInRight"
                        animationInDelay={500}
                        animationInDuration={500}
                        isVisible={true}
                      >
                        <h1>Elevate Your Career To Grow Higher</h1>
                        <p>Learn technology from the best.</p>
                        {/* <div className="cus-button">
                          <Link
                            to={`${process.env.PUBLIC_URL}/contact`}
                            className="cusbtn text-white"
                          >
                            {" "}
                            Enquiry
                          </Link>
                          <div className="cusbtnOver"></div>
                        </div> */}
                      </Animated>
                    </Col>
                  </Col>
                </Row>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </section>

        <section className="p-0">
          <div className="aboutBg">
            <Container>
              <Row>
                <Col className="aboutContM" sm={12} md={12}></Col>
                <Col
                  className="aboutCont"
                  sm={12}
                  md={12}
                  lg={{ span: 6, offset: 6 }}
                >
                  <Animated
                    animationIn="fadeInUp"
                    animationInDelay={1000}
                    animationInDuration={1000}
                    isVisible={true}
                  >
                    <h3>
                      About <span>GRABB</span>
                    </h3>
                    <p>
                      GRABB provides world-class facilities and experienced
                      faculty to our students. Our software programs are
                      designed to meet the ever-changing needs of the IT
                      industry. We offer a wide range of programs that cover all
                      aspects of software development, testing, and maintenance.
                    </p>
                    <p>
                      Our goal is to equip fresh graduates to kick-start their
                      careers easily and professionals to upskill for their
                      career enhancement.
                    </p>
                    <div className="cus-button">
                      <Link
                        to={`${process.env.PUBLIC_URL}/about`}
                        className="cusbtn"
                      >
                        {" "}
                        Know more
                      </Link>
                      <div className="cusbtnOver"></div>
                    </div>
                  </Animated>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <section className="whyBestBg pb-0">
          <Container className="titleHeading courseCont">
            <Animated
              animationIn="fadeIn"
              animationInDelay={1500}
              animationInDuration={1500}
              isVisible={true}
            >
              <h3 className="title text-center text-white pb-3">Our Courses</h3>
            </Animated>
          </Container>
          <Container className="courseCont">
            <div className="text-center">
              <Carousel fade className=" d-none d-md-block partCourse">
                <Carousel.Item>
                  <div className="featureProgress">
                    <div className="loading-bar-feat">
                      <div className="progress-bar"></div>
                    </div>
                  </div>
                  <Container className="">
                    <Row className="mt-4">
                      <Col
                        className="courseTitle d-flex align-items-center justify-content-center"
                        sm={12}
                        md={4}
                        lg={4}
                      >
                        <div className="mb-4">
                          <h4>Featured Courses</h4>
                          <span>Over 25 Courses</span>
                          <div className="mt-2">
                            <div className="cus-button">
                              <Link
                                to={`${process.env.PUBLIC_URL}/featuredcourse`}
                                className="cusbtn text-white"
                              >
                                {" "}
                                Know More
                              </Link>
                              <div className="cusbtnOver"></div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        className="text-white coursedetail mb-2"
                        sm={12}
                        md={8}
                        lg={8}
                      >
                        <Row>
                          <Col className="mb-4" sm={12} md={6} lg={4}>
                            <div className="coursedetCard">
                              <div className="img">
                                <img
                                  className=""
                                  src={Clang}
                                  alt="First slide"
                                  width="15"
                                />
                              </div>
                              <div className="detail">
                                <span>
                                  Learn C and C++ Build a strong programming
                                  foundation by learning C and C++
                                </span>
                              </div>
                              <div className="content text-center py-3">
                                <h5 className="py-2">C and C++</h5>
                              </div>
                            </div>
                          </Col>
                          <Col className="mb-4 " sm={12} md={6} lg={4}>
                            <a className="link coursedetCard">
                              <div className="img">
                                <img
                                  className=""
                                  src={Python}
                                  alt="First slide"
                                  width="15"
                                />
                              </div>
                              <div className="detail">
                                <span>
                                  Python is a high-level, general-purpose
                                  programming language.
                                </span>
                              </div>
                              <div className="content text-center py-3">
                                <h5 className="py-2">Python</h5>
                              </div>
                            </a>
                          </Col>
                          <Col className="mb-4" sm={12} md={6} lg={4}>
                            <div className="coursedetCard">
                              <div className="img">
                                <img
                                  className=""
                                  src={Oracle}
                                  alt="First slide"
                                  width="15"
                                />
                              </div>
                              <div className="detail">
                                <span>
                                  Oracle is a product from Oracle Corporation
                                  that provides a relational database management
                                  system.
                                </span>
                              </div>
                              <div className="content text-center py-3">
                                <h5 className="py-2">Oracle</h5>
                              </div>
                            </div>
                          </Col>
                          <Col
                            className="mb-4 d-block d-lg-none"
                            sm={12}
                            md={6}
                          >
                            <div className="coursedetCard">
                              <div className="img">
                                <img
                                  className=""
                                  src={Selenium}
                                  alt="First slide"
                                  width="15"
                                />
                              </div>
                              <div className="detail">
                                <span>
                                  Selenium is an open source umbrella project
                                  for a range of tools and libraries aimed at
                                  supporting.
                                </span>
                              </div>
                              <div className="content text-center py-3">
                                <h5 className="py-2">Selenium</h5>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="transProgress">
                    <div className="loading-bar-trans">
                      <div className="progress-bar"></div>
                    </div>
                  </div>
                  <Container className="">
                    <Row className="mt-4">
                      <Col
                        className="courseTitle d-flex align-items-center justify-content-center"
                        sm={12}
                        md={4}
                        lg={4}
                      >
                        <div className="mb-4">
                          <h4>Transition Courses</h4>
                          <span>Over 12 Courses</span>
                          <div className="mt-2">
                            <div className="cus-button">
                              <Link
                                to={`${process.env.PUBLIC_URL}/transitioncourse`}
                                className="cusbtn text-white"
                              >
                                {" "}
                                Know More
                              </Link>
                              <div className="cusbtnOver"></div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        className="text-white coursedetail mb-2"
                        sm={12}
                        md={8}
                        lg={8}
                      >
                        <Row>
                          <Col className="mb-4" sm={12} md={6} lg={4}>
                            <div className="coursedetCard">
                              <div className="img">
                                <img
                                  className=""
                                  src={SoftwareTesting}
                                  alt="First slide"
                                  width="15"
                                />
                              </div>
                              <div className="detail">
                                <span>
                                  Software testing is the act of examining the
                                  artifacts and the behaviour of the software
                                  under test.
                                </span>
                              </div>
                              <div className="content text-center py-3">
                                <h5 className="py-2">Software testing</h5>
                              </div>
                            </div>
                          </Col>
                          <Col className="mb-4" sm={12} md={6} lg={4}>
                            <div className="coursedetCard">
                              <div className="img">
                                <img
                                  className=""
                                  src={Webdesign}
                                  alt="First slide"
                                  width="15"
                                />
                              </div>
                              <div className="detail">
                                <span>
                                  Designing a website is all about creating web
                                  pages for running online businesses that help
                                  in building
                                </span>
                              </div>
                              <div className="content text-center py-3">
                                <h5 className="py-2">Web Designing</h5>
                              </div>
                            </div>
                          </Col>
                          <Col className="mb-4" sm={12} md={6} lg={4}>
                            <div className="coursedetCard">
                              <div className="img">
                                <img
                                  className=""
                                  src={Java}
                                  alt="First slide"
                                  width="15"
                                />
                              </div>
                              <div className="detail">
                                <span>
                                  This Java certification course online is
                                  designed for students and professionals who
                                  want to be Java Developer
                                </span>
                              </div>
                              <div className="content text-center py-3">
                                <h5 className="py-2">Java</h5>
                              </div>
                            </div>
                          </Col>
                          <Col
                            className="mb-4 d-block d-lg-none"
                            sm={12}
                            md={6}
                          >
                            <div className="coursedetCard">
                              <div className="img">
                                <img
                                  className=""
                                  src={DigitalMarketing}
                                  alt="First slide"
                                  width="15"
                                />
                              </div>
                              <div className="detail">
                                <span>
                                  Digital marketing is the component of
                                  marketing that uses the Internet and online
                                  based digital
                                </span>
                              </div>
                              <div className="content text-center py-3">
                                <h5 className="py-2">Digital marketing</h5>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="onlineProgress">
                    <div className="loading-bar-online">
                      <div className="progress-bar"></div>
                    </div>
                  </div>
                  <Container className="">
                    <Row className="mt-4">
                      <Col
                        className="courseTitle d-flex align-items-center justify-content-center"
                        sm={12}
                        md={4}
                        lg={4}
                      >
                        <div className="mb-4">
                          <h4>Online Courses</h4>
                          <span>Over 04 Courses</span>
                          <div className="mt-2">
                            <div className="cus-button">
                              <Link
                                to={`${process.env.PUBLIC_URL}/onlinecourse`}
                                className="cusbtn text-white"
                              >
                                {" "}
                                Know More
                              </Link>
                              <div className="cusbtnOver"></div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        className="text-white coursedetail mb-2"
                        sm={12}
                        md={8}
                        lg={8}
                      >
                        <Row>
                          <Col className="mb-4" sm={12} md={6} lg={4}>
                            <div className="coursedetCard">
                              <div className="img">
                                <img
                                  className=""
                                  src={Blockchain}
                                  alt="First slide"
                                  width="15"
                                />
                              </div>
                              <div className="detail">
                                <span>
                                  Blockchain is a shared, immutable ledger for
                                  recording transactions, tracking
                                </span>
                              </div>
                              <div className="content text-center py-3">
                                <h5 className="py-2">Blockchain</h5>
                              </div>
                            </div>
                          </Col>
                          <Col className="mb-4" sm={12} md={6} lg={4}>
                            <div className="coursedetCard">
                              <div className="img">
                                <img
                                  className=""
                                  src={AI}
                                  alt="First slide"
                                  width="15"
                                />
                              </div>
                              <div className="detail">
                                <span>
                                  Artificial Intelligence & Machine Learning for
                                  Professionals is an immersive
                                </span>
                              </div>
                              <div className="content text-center py-3">
                                <h5 className="py-2">AI & ML</h5>
                              </div>
                            </div>
                          </Col>
                          <Col className="mb-4" sm={12} md={6} lg={4}>
                            <div className="coursedetCard">
                              <div className="img">
                                <img
                                  className=""
                                  src={CyberSecurity}
                                  alt="First slide"
                                  width="15"
                                />
                              </div>
                              <div className="detail">
                                <span>
                                  Cyber security course on GRABB Private
                                  Limited, and gain skills
                                </span>
                              </div>
                              <div className="content text-center py-3">
                                <h5 className="py-2">Cyber Security</h5>
                              </div>
                            </div>
                          </Col>
                          <Col
                            className="mb-4 d-block d-lg-none"
                            sm={12}
                            md={6}
                          >
                            <div className="coursedetCard">
                              <div className="img">
                                <img
                                  className=""
                                  src={Web3}
                                  alt="First slide"
                                  width="15"
                                />
                              </div>
                              <div className="detail">
                                <span>
                                  Web3 (also known as Web 3.0) is an idea for a
                                  new iteration
                                </span>
                              </div>
                              <div className="content text-center py-3">
                                <h5 className="py-2">WEB 3.0</h5>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Item>
              </Carousel>
              <div className="coursebtnPos d-none d-md-block">
                <Link
                  data-bs-target
                  aria-label="Slide 1"
                  className="btn butn btn-sm nav_border me-3 mt-2"
                >
                  {" "}
                  Featured Courses
                </Link>
                <Link
                  data-bs-target
                  aria-label="Slide 2"
                  className="btn butn btn-sm nav_border me-3 mt-2"
                >
                  {" "}
                  Transition Courses
                </Link>
                <Link
                  data-bs-target
                  aria-label="Slide 3"
                  className="btn butn btn-sm nav_border me-3 mt-2"
                >
                  {" "}
                  Online Courses
                </Link>
              </div>

              <Container>
                <div className="mb-4 text-white d-block d-md-none">
                  <h4>Featured Courses</h4>
                  <span>Over 25 Courses</span>
                </div>
                <Row>
                  <Col className="mb-4 d-block d-md-none" sm={12}>
                    <div className="coursedetCard">
                      <div className="img">
                        <img
                          className=""
                          src={Clang}
                          alt="First slide"
                          width="15"
                        />
                      </div>
                      <div className="detail">
                        <span>
                          Learn C and C++ Build a strong programming foundation
                          by learning C and C++
                        </span>
                      </div>
                      <div className="content text-center py-3">
                        <h5 className="py-2">C and C++</h5>
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 d-block d-md-none" sm={12}>
                    <div className="coursedetCard">
                      <div className="img">
                        <img
                          className=""
                          src={Python}
                          alt="First slide"
                          width="15"
                        />
                      </div>
                      <div className="detail ">
                        <span>
                          Python is a high-level, general-purpose programming
                          language.
                        </span>
                      </div>
                      <div className="content text-center py-3">
                        <h5 className="py-2">Python</h5>
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 d-block d-md-none" sm={12}>
                    <div className="coursedetCard">
                      <div className="img">
                        <img
                          className=""
                          src={Oracle}
                          alt="First slide"
                          width="15"
                        />
                      </div>
                      <div className="detail">
                        <span>
                          Oracle is a product from Oracle Corporation that
                          provides a relational database management system.
                        </span>
                      </div>
                      <div className="content text-center py-3">
                        <h5 className="py-2">Oracle</h5>
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 d-block d-md-none" sm={12}>
                    <div className="coursedetCard">
                      <div className="img">
                        <img
                          className=""
                          src={Selenium}
                          alt="First slide"
                          width="15"
                        />
                      </div>
                      <div className="detail">
                        <span>
                          Selenium is an open source umbrella project for a
                          range of tools and libraries aimed at supporting.
                        </span>
                      </div>
                      <div className="content text-center py-3">
                        <h5 className="py-2">Selenium</h5>
                      </div>
                    </div>
                  </Col>
                  <div className="mt-2 d-block d-md-none">
                    <div className="cus-button">
                      <Link
                        to={`${process.env.PUBLIC_URL}/featuredcourse`}
                        className="cusbtn"
                      >
                        {" "}
                        Know More
                      </Link>
                      <div className="cusbtnOver"></div>
                    </div>
                  </div>
                </Row>
                <div className="mb-4 text-white d-block d-md-none">
                  <h4>Transition Courses</h4>
                  <span>Over 12 Courses</span>
                </div>
                <Row>
                  <Col className="mb-4 d-block d-md-none" sm={12}>
                    <div className="coursedetCard">
                      <div className="img">
                        <img
                          className=""
                          src={SoftwareTesting}
                          alt="First slide"
                          width="15"
                        />
                      </div>
                      <div className="detail">
                        <span>
                          Software testing is the act of examining the artifacts
                          and the behaviour of the software under test.
                        </span>
                      </div>
                      <div className="content text-center py-3">
                        <h5 className="py-2">Software testing</h5>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="cus-button">
                        <Link
                          to={`${process.env.PUBLIC_URL}/transitioncourse`}
                          className="cusbtn text-white"
                        >
                          {" "}
                          Know More
                        </Link>
                        <div className="cusbtnOver"></div>
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 d-block d-md-none" sm={12}>
                    <div className="coursedetCard">
                      <div className="img">
                        <img
                          className=""
                          src={Webdesign}
                          alt="First slide"
                          width="15"
                        />
                      </div>
                      <div className="detail">
                        <span>
                          Designing a website is all about creating web pages
                          for running online businesses that help in building
                        </span>
                      </div>
                      <div className="content text-center py-3">
                        <h5 className="py-2">Web Designing</h5>
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 d-block d-md-none" sm={12}>
                    <div className="coursedetCard">
                      <div className="img">
                        <img
                          className=""
                          src={Java}
                          alt="First slide"
                          width="15"
                        />
                      </div>
                      <div className="detail">
                        <span>
                          This Java certification course online is designed for
                          students and professionals who want to be Java
                          Developer
                        </span>
                      </div>
                      <div className="content text-center py-3">
                        <h5 className="py-2">Java</h5>
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 d-block d-md-none" sm={12}>
                    <div className="coursedetCard">
                      <div className="img">
                        <img
                          className=""
                          src={DigitalMarketing}
                          alt="First slide"
                          width="15"
                        />
                      </div>
                      <div className="detail">
                        <span>
                          Digital marketing is the component of marketing that
                          uses the Internet and online based digital
                        </span>
                      </div>
                      <div className="content text-center py-3">
                        <h5 className="py-2">Digital marketing</h5>
                      </div>
                    </div>
                  </Col>
                  <div className="mt-2 d-block d-md-none">
                    <div className="cus-button">
                      <Link
                        to={`${process.env.PUBLIC_URL}/transitioncourse`}
                        className="cusbtn"
                      >
                        {" "}
                        Know More
                      </Link>
                      <div className="cusbtnOver"></div>
                    </div>
                  </div>
                </Row>
                <div className="mb-4 text-white d-block d-md-none">
                  <h4>Online Courses</h4>
                  <span>Over 04 Courses</span>
                </div>
                <Row>
                  <Col className="mb-4 d-block d-md-none" sm={12}>
                    <div className="coursedetCard">
                      <div className="img">
                        <img
                          className=""
                          src={Blockchain}
                          alt="First slide"
                          width="15"
                        />
                      </div>
                      <div className="detail">
                        <span>
                          Blockchain is a shared, immutable ledger for recording
                          transactions, tracking
                        </span>
                      </div>
                      <div className="content text-center py-3">
                        <h5 className="py-2">Blockchain</h5>
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 d-block d-md-none" sm={12}>
                    <div className="coursedetCard">
                      <div className="img">
                        <img
                          className=""
                          src={AI}
                          alt="First slide"
                          width="15"
                        />
                      </div>
                      <div className="detail">
                        <span>
                          Artificial Intelligence & Machine Learning for
                          Professionals is an immersive
                        </span>
                      </div>
                      <div className="content text-center py-3">
                        <h5 className="py-2">AI & ML</h5>
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 d-block d-md-none" sm={12}>
                    <div className="coursedetCard">
                      <div className="img">
                        <img
                          className=""
                          src={CyberSecurity}
                          alt="First slide"
                          width="15"
                        />
                      </div>
                      <div className="detail">
                        <span>
                          Cyber security course on GRABB Private Limited, and
                          gain skills
                        </span>
                      </div>
                      <div className="content text-center py-3">
                        <h5 className="py-2">Cyber Security</h5>
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 d-block d-md-none" sm={12}>
                    <div className="coursedetCard">
                      <div className="img">
                        <img
                          className=""
                          src={Web3}
                          alt="First slide"
                          width="15"
                        />
                      </div>
                      <div className="detail">
                        <span>
                          Web3 (also known as Web 3.0) is an idea for a new
                          iteration
                        </span>
                      </div>
                      <div className="content text-center py-3">
                        <h5 className="py-2">WEB 3.0</h5>
                      </div>
                    </div>
                  </Col>
                  <div className="mt-2 d-block d-md-none">
                    <div className="cus-button">
                      <Link
                        to={`${process.env.PUBLIC_URL}/onlinecourse`}
                        className="cusbtn"
                      >
                        {" "}
                        Know More
                      </Link>
                      <div className="cusbtnOver"></div>
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
          </Container>
        </section>

        <section id="features" className="features">
          <Container>
            <div className="titleHeading courseCont">
              <Animated
                animationIn="fadeIn"
                animationInDelay={1500}
                animationInDuration={1500}
                isVisible={true}
              >
                <h3 className="title text-center pb-3">Why we are Best ?</h3>
              </Animated>
            </div>
            <Row>
              <Col lg={7} className="mt-5 mt-lg-0 d-flex">
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={2000}
                  animationInDuration={2000}
                  isVisible={true}
                >
                  <Row className="align-self-center gy-4">
                    <Col md={6}>
                      <div className="feature-box d-flex align-items-center">
                        <BsCheckLg size={24} />
                        <h3>Our Trending Courses</h3>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="feature-box d-flex align-items-center">
                        <BsCheckLg size={24} />
                        <h3>Team of Experts</h3>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="feature-box d-flex align-items-center">
                        <BsCheckLg size={24} />
                        <h3>100% Placement</h3>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="feature-box d-flex align-items-center">
                        <BsCheckLg size={24} />
                        <h3>Interview Preparation</h3>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="feature-box d-flex align-items-center">
                        <BsCheckLg size={24} />
                        <h3>Classroom Training</h3>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="feature-box d-flex align-items-center">
                        <BsCheckLg size={24} />
                        <h3>Online Training</h3>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="feature-box d-flex align-items-center">
                        <BsCheckLg size={24} />
                        <h3>Corporate Training</h3>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="feature-box d-flex align-items-center">
                        <BsCheckLg size={24} />
                        <h3>LIVE Project</h3>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="feature-box d-flex align-items-center">
                        <BsCheckLg size={24} />
                        <h3>Frequent Webinars</h3>
                      </div>
                    </Col>
                  </Row>
                </Animated>
              </Col>
              <Col lg={5} className="text-center">
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={2000}
                  animationInDuration={2000}
                  isVisible={true}
                >
                  <img src={Webest} className="img-fluid webest" alt="" />
                  <div className="cus-button my-3">
                    <Link
                      to={`${process.env.PUBLIC_URL}/about`}
                      className="cusbtn"
                    >
                      {" "}
                      Know more
                    </Link>
                    <div className="cusbtnOver"></div>
                  </div>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pt-0">
          <div className="insBg">
            <Container>
              <Col className="head text-center mx-auto" sm={12} md={6}>
                <Animated
                  animationIn="fadeIn"
                  animationInDelay={2500}
                  animationInDuration={2500}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    How to Find the Best Software Training Institute in Chennai?
                  </h3>
                </Animated>
              </Col>
            </Container>
            <Container className="content">
              <Col className="mx-auto bg-white" sm={12} md={12} lg={10}>
                <Card>
                  <Card.Body className="cont">
                    <p>
                      Finding a good software training institute can be a
                      challenge, especially in a city like Chennai. But don’t
                      worry, we’ve got you covered. GRABB is the leading
                      provider of software training programs in Chennai, and
                      we’re here to help you get the skills you need to succeed
                      in your career.
                    </p>
                    <p className="mb-0">
                      Our programs are designed to give you the latest and
                      greatest skills in the industry, so you can stay ahead of
                      the curve and land the job you want. So, what are you
                      waiting for? Contact us today to learn more about our
                      software training programs, and get started on your path
                      to success!
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Container>
          </div>
        </section>

        <section className="testimonial_section section_bg">
          <Container>
            <Row>
              <Col lg={7}>
                <div className="about_content">
                  <div className="background_layer"></div>
                  <div className="layer_content">
                    <Animated
                      animationIn="fadeIn"
                      animationInDelay={3000}
                      animationInDuration={3000}
                      isVisible={true}
                    >
                      <div className="section_title">
                        <h5 className="text-uppercase">Testimonial</h5>
                        <h2>
                          <RiDoubleQuotesL /> What Our Students Say
                          <RiDoubleQuotesR />
                        </h2>
                        <div className="heading_line">
                          <span></span>
                        </div>
                        <p>
                          Read how our student course and placement are
                          effectively implemented in their organizations with
                          Grabb Pvt Ltd.
                        </p>
                      </div>
                      <a href="#">
                        Read More <i className="icofont-long-arrow-right"></i>
                      </a>
                    </Animated>
                  </div>
                </div>
              </Col>
              <Col lg={5}>
                <div className="testimonial_box">
                  <div className="testimonial_container">
                    <div className="background_layer"></div>
                    <div className="layer_content">
                      <Animated
                        animationIn="fadeIn"
                        animationInDelay={3500}
                        animationInDuration={3500}
                        isVisible={true}
                      >
                        <OwlCarousel
                          className="owl-theme testimonial_owlCarousel"
                          loop
                          margin={10}
                          nav
                          {...testimonial}
                        >
                          <div className="testimonials item">
                            <div className="testimonial_content">
                              <div className="testimonial_caption">
                                <h6>Miss. Monisha</h6>
                                {/* <span>CEO, Axura</span> */}
                              </div>
                              <p>
                                It was happy to share my experience with GRABB
                                Private Limited. I started my JAVA course in
                                GRABB Private Limited. Expert knowledge of JAVA
                                expert with best and friendly...
                              </p>
                            </div>
                            <div className="images_box">
                              <div className="testimonial_img">
                                <img
                                  className="img-center"
                                  src={Avatar1}
                                  alt="images not found"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="testimonials item">
                            <div className="testimonial_content">
                              <div className="testimonial_caption">
                                <h6>Mr. Sangameshwaran</h6>
                                {/* <span>CEO, Axura</span> */}
                              </div>
                              <p>
                                Before joining GRABB Private Limited my
                                knowledge was limited to my academic syllabus
                                only. But now after joining GRABB Private
                                Limited my knowledge has elaborated. I learnt...
                              </p>
                            </div>
                            <div className="images_box">
                              <div className="testimonial_img">
                                <img
                                  className="img-center"
                                  src={Avatar2}
                                  alt="images not found"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="testimonials item">
                            <div className="testimonial_content">
                              <div className="testimonial_caption">
                                <h6>Mr. Purushothaman</h6>
                                {/* <span>CEO, Axura</span> */}
                              </div>
                              <p>
                                It was one of the best learning experiences I
                                had at GRABB Private Limited, right from
                                counselling to placements. GRABB Private Limited
                                has counsellors with appropriate...
                              </p>
                            </div>
                            <div className="images_box">
                              <div className="testimonial_img">
                                <img
                                  className="img-center"
                                  src={Avatar3}
                                  alt="images not found"
                                />
                              </div>
                            </div>
                          </div>
                        </OwlCarousel>
                      </Animated>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section className="cta_bg">
          <Container>
            <Row className="justify-content-md-center align-items-md-center">
              <Col sm={12} md={5} lg={{ span: 3, offset: 1 }}>
                <Animated
                  animationIn="fadeIn"
                  animationInDelay={4000}
                  animationInDuration={4000}
                  isVisible={true}
                >
                  <img
                    className=""
                    src={CTA}
                    style={{ borderRadius: 20 }}
                    alt="Image Description"
                  />
                </Animated>
              </Col>

              <Col sm={12} md={7} lg={{ span: 6, offset: 1 }}>
                <Animated
                  animationIn="fadeIn"
                  animationInDelay={4000}
                  animationInDuration={4000}
                  isVisible={true}
                >
                  <div className="mb-5">
                    <h2 className="text-light mb-1">
                      Start talking to your users today
                    </h2>
                    <p className="lead text-light pt-4">
                      14 day free trial. No credit card required.
                    </p>
                  </div>

                  <div className="cus-button">
                    <Link
                      to={`${process.env.PUBLIC_URL}/contact`}
                      className="cusbtn"
                    >
                      {" "}
                      Enquiry
                    </Link>
                    <div className="cusbtnOver"></div>
                  </div>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section> */}
        <Gototop />
      </>
    );
  }
}

export default Home;
