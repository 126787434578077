import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import WebImg from "../../assets/img/courses/web.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class Web extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Web 3.0</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Web 3.0?</h3>
                  <p>
                    Web3 (also known as Web 3.0) is an idea for a new iteration
                    of the World Wide Web which incorporates concepts such as
                  </p>
                  <ol>
                    <li>Decentralization</li>
                    <li>Blockchain technologies and</li>
                    <li>Token-based economics</li>
                  </ol>
                  <h3>Overview</h3>
                  <p>
                    Web 3 represents the next wave of the internet, built on
                    decentralized networks that promote much greater individual
                    control and privacy. To fuel this innovation, companies are
                    scrambling to hire Web3 developers.
                  </p>
                  <h3>What is the format for your Web3 Development course?</h3>
                  <p>
                    Our Web3 Development course is an online, on-demand program
                    you can work through at your own pace. You’ll learn Web3
                    skills through recorded lessons taught by industry experts,
                    study materials and resources, and independent projects that
                    apply the skills you learn.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img className="img-fluid" src={WebImg} alt="First slide" />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    Who is the Web 3 Development course for?
                  </h3>
                  <p>
                    This program is designed for software engineers or
                    developers who want to specialize or keep their skills
                    up-to-date. The course is not for beginners or those looking
                    for their first job in tech. If you want to begin a career
                    in tech, our Full Stack Web Development and Data Science
                    courses will be a better fit for you.
                  </p>
                  <h3 className="text-white">
                    What kind of jobs can I get after I learn Web3 skills?
                  </h3>
                  <p>
                    Currently, the job market for software engineers who can
                    demonstrate Web3 skills is red-hot, and starting salaries
                    are typically higher than other coding jobs. Web3 roles
                    include Web3 developer, Blockchain developer, smart contract
                    engineer, NFT developer, and Metaverse developer.
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default Web;
