import React, { useState, Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  Figure,
  Modal,
} from "react-bootstrap";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { BsStarFill, BsHandThumbsUpFill } from "react-icons/bs";
import { HiTrendingUp } from "react-icons/hi";
import { RiTeamFill, RiLiveFill } from "react-icons/ri";
import { FaHandsHelping } from "react-icons/fa";
import {
  MdOutlineModelTraining,
  MdCorporateFare,
  MdOndemandVideo,
} from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import { Animated } from "react-animated-css";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/videocover.jpg";
import TrendImg from "../../assets/img/best/trending.jpg";
import TeamImg from "../../assets/img/best/team.jpg";
import PlacementImg from "../../assets/img/best/placement.jpg";
import InterviewImg from "../../assets/img/best/interview.jpg";
import ClassroomImg from "../../assets/img/best/classroom.jpg";
import OnlineImg from "../../assets/img/best/onlinetraining.jpg";
import CorporateImg from "../../assets/img/best/corporate.jpg";
import LiveImg from "../../assets/img/best/live.jpg";
import WebinarImg from "../../assets/img/best/webinar.jpg";
import ABC from "../../assets/img/abc.png";
import $ from "jquery";

class About extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="innerBanner aboutinnerBg">
          <Container>
            <div>
              <h3 className="text-white text-center">About Us</h3>
            </div>
          </Container>
        </section>

        <section className="section_bg about">
          <Container className="">
            <Row>
              <div className="positionRelative">
                <div className="col-lg-6 about-img d-flex align-items-center">
                  <Animated
                    animationIn="fadeInRight"
                    animationInDelay={500}
                    animationInDuration={500}
                    isVisible={true}
                  >
                    <a
                      href="https://www.youtube.com/watch?v=_5nwTCyb44Q"
                      className="open"
                      target="_blank"
                    >
                      <img
                        src={AboutImg}
                        className="img-fluid mb-5 about"
                        height={"360px"}
                      />
                      <a
                        href="https://www.youtube.com/watch?v=_5nwTCyb44Q"
                        target="_blank"
                        className="aboutLink"
                      >
                        <div className="play-square"></div>
                      </a>
                    </a>
                  </Animated>
                </div>

                <div className="col-lg-7">
                  <div className="our-story">
                    <Animated
                      animationIn="fadeInLeft"
                      animationInDelay={500}
                      animationInDuration={500}
                      isVisible={true}
                    >
                      <div className="header_section">
                        <h3>Our Story</h3>
                      </div>
                      <p>
                        GRABB provides world-class facilities and experienced
                        faculty to our students. Our software programs are
                        designed to meet the ever-changing needs of the IT
                        industry. We offer a wide range of programs that cover
                        all aspects of software development, testing, and
                        maintenance.
                      </p>
                      <p>
                        Our goal is to equip fresh graduates to kick-start their
                        careers easily and professionals to upskill for their
                        career enhancement.
                      </p>
                    </Animated>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </section>

        <section className="pt-0">
          <div className="aboutDet">
            <Container>
              <Col className="head text-center mx-auto" sm={12} md={6}>
                <Animated
                  animationIn="fadeIn"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">WE ARE ALWAYS BEST</h3>
                </Animated>
              </Col>
            </Container>
            <Container className="content">
              <Col className="mx-auto" sm={12} md={12} lg={12}>
                <Card className="">
                  <Row>
                    <Col sm={12} md={12} lg={6}>
                      <Animated
                        animationIn="fadeIn"
                        animationInDelay={1500}
                        animationInDuration={1500}
                        isVisible={true}
                      >
                        <div className="aboutD p-3">
                          <p>
                            We have a team of highly qualified and experienced
                            trainers who are passionate about their work. They
                            are always ready to help our students in every way
                            possible. We also have a well-equipped lab with all
                            the latest software and tools. Our students get
                            ample opportunities to work on real-time projects
                            and gain practical exposure.
                          </p>
                          <p>
                            We believe in providing quality education at an
                            affordable price. Our programs are designed keeping
                            in mind the budget constraints of our students. We
                            offer flexible payment options and discounts for
                            early bird enrolments.
                          </p>
                          <p className="mb-0">
                            So, if you are looking for a comprehensive software
                            training program that will help you get ahead in
                            your career, then GRABB is the right choice for you!
                          </p>
                        </div>
                      </Animated>
                    </Col>
                    <Col sm={12} md={12} lg={6}>
                      <div className="img-box"></div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Container>
          </div>
          <div className="clearfix"></div>
        </section>

        <section className="services">
          <Container>
            <div className="section-header mb-5">
              <h2>Why we are Best ?</h2>
              <p>
                Ea vitae aspernatur deserunt voluptatem impedit deserunt magnam
                occaecati dssumenda quas ut ad dolores adipisci aliquam.
              </p>
            </div>
            <Row className="gy-5">
              <Col xl={4} md={6}>
                <div className="service-item">
                  <div className="img">
                    <img src={TrendImg} className="img-fluid" alt="" />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <HiTrendingUp />
                    </div>
                    <h3>Our Trending courses</h3>
                    <p>
                      <ReactReadMoreReadLess
                        charLimit={125}
                        readMoreText={"Read more"}
                        readLessText={"Read less"}
                      >
                        GRABB software training program is best for those who
                        want to start their career in software development or
                        testing. The program is also beneficial for experienced
                        software professionals who want to update their skills
                        or learn new technologies. The program covers all the
                        essential topics related to software development and
                        testing and provides practical training on various tools
                        and techniques.
                      </ReactReadMoreReadLess>
                    </p>
                  </div>
                </div>
              </Col>

              <Col xl={4} md={6}>
                <div className="service-item">
                  <div className="img">
                    <img src={TeamImg} className="img-fluid" alt="" />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <RiTeamFill />
                    </div>
                    <h3>Team of Experts</h3>
                    <p>
                      <ReactReadMoreReadLess
                        charLimit={125}
                        readMoreText={"Read more"}
                        readLessText={"Read less"}
                      >
                        All the trainers at GRABB are industry experts with
                        years of experience in their respective fields. The
                        curriculum is regularly updated to ensure that our
                        students get the best possible education and prepare
                        them for a successful career in the software industry.
                      </ReactReadMoreReadLess>
                    </p>
                  </div>
                </div>
              </Col>

              <Col xl={4} md={6}>
                <div className="service-item">
                  <div className="img">
                    <img src={PlacementImg} className="img-fluid" alt="" />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <BsHandThumbsUpFill />
                    </div>
                    <h3>100% Placement</h3>
                    <p>
                      <ReactReadMoreReadLess
                        charLimit={125}
                        readMoreText={"Read more"}
                        readLessText={"Read less"}
                      >
                        GRABB provides the best software training program in
                        Chennai with placements. The program is designed to
                        provide the students with the best possible learning
                        experience and to prepare them for the industry. The
                        program also includes a placement assistance program to
                        help the students find jobs after completion of the
                        course.
                      </ReactReadMoreReadLess>
                    </p>
                  </div>
                </div>
              </Col>

              <Col xl={4} md={6}>
                <div className="service-item">
                  <div className="img">
                    <img src={InterviewImg} className="img-fluid" alt="" />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <FaHandsHelping />
                    </div>
                    <h3>Interview Preparation</h3>{" "}
                    <p>
                      <ReactReadMoreReadLess
                        charLimit={125}
                        readMoreText={"Read more"}
                        readLessText={"Read less"}
                      >
                        Every course in covered with interview point questions
                        and real-time scenarios of what the company may look
                        after from you.
                      </ReactReadMoreReadLess>
                    </p>
                  </div>
                </div>
              </Col>

              <Col xl={4} md={6}>
                <div className="service-item">
                  <div className="img">
                    <img src={ClassroomImg} className="img-fluid" alt="" />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <MdOutlineModelTraining />
                    </div>
                    <h3>Classroom Training</h3>{" "}
                    <p>
                      <ReactReadMoreReadLess
                        charLimit={125}
                        readMoreText={"Read more"}
                        readLessText={"Read less"}
                      >
                        We have the best faculty with excellent Lab
                        Infrastructure along with detailed course material.
                      </ReactReadMoreReadLess>
                    </p>
                  </div>
                </div>
              </Col>

              <Col xl={4} md={6}>
                <div className="service-item">
                  <div className="img">
                    <img src={OnlineImg} className="img-fluid" alt="" />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <SiGoogleclassroom />
                    </div>
                    <h3>Online Training</h3>{" "}
                    <p>
                      <ReactReadMoreReadLess
                        charLimit={125}
                        readMoreText={"Read more"}
                        readLessText={"Read less"}
                      >
                        We offer most of the course online with virtual
                        classroom to serve the IT aspirants.
                      </ReactReadMoreReadLess>
                    </p>
                  </div>
                </div>
              </Col>

              <Col xl={4} md={6}>
                <div className="service-item">
                  <div className="img">
                    <img src={CorporateImg} className="img-fluid" alt="" />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <MdCorporateFare />
                    </div>
                    <h3>Corporate Training</h3>{" "}
                    <p>
                      <ReactReadMoreReadLess
                        charLimit={125}
                        readMoreText={"Read more"}
                        readLessText={"Read less"}
                      >
                        We have experienced faculty to train professionals for
                        improving their technology skills in their profession.
                      </ReactReadMoreReadLess>
                    </p>
                  </div>
                </div>
              </Col>

              <Col xl={4} md={6}>
                <div className="service-item">
                  <div className="img">
                    <img src={LiveImg} className="img-fluid" alt="" />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <RiLiveFill />
                    </div>
                    <h3>LIVE Project</h3>{" "}
                    <p>
                      <ReactReadMoreReadLess
                        charLimit={125}
                        readMoreText={"Read more"}
                        readLessText={"Read less"}
                      >
                        Get an Opportunity to work in Real-time Projects that
                        will give you a great Experience. Showcase your Project
                        Experience & Increase your chance of getting Hired!
                      </ReactReadMoreReadLess>
                    </p>
                  </div>
                </div>
              </Col>

              <Col xl={4} md={6}>
                <div className="service-item">
                  <div className="img">
                    <img src={WebinarImg} className="img-fluid" alt="" />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <MdOndemandVideo />
                    </div>
                    {/*  <a className="stretched-link">
                     
                    </a> */}
                    <h3>Frequent Webinars</h3>{" "}
                    <p>
                      <ReactReadMoreReadLess
                        charLimit={125}
                        readMoreText={"Read more"}
                        readLessText={"Read less"}
                      >
                        Students will attend frequent Webinars by Industry
                        Experts to know the current trend & business platform to
                        succeed. It cover a range of topics related to software
                        development, from introductory tutorials to advanced
                        topics such as testing and debugging. The webinar can
                        also feature guest speakers from the software industry
                        who can provide insights and best practices for software
                        development. The webinar can be tailored to the needs of
                        the students and can be delivered in a variety of
                        formats, including live, on-demand, and recorded
                        formats.
                      </ReactReadMoreReadLess>
                    </p>
                    {/*<a href="#" className="stretched-link"></a>*/}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Gototop />
      </>
    );
  }
}

export default About;
