import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import JavaImg from "../../assets/img/courses/java.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class Java extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Java</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Java?</h3>
                  <p>
                    This Java certification course online is designed for
                    students and professionals who want to be Java Developer.
                  </p>
                  <p>
                    This 42-hour Java training will cover core and advanced Java
                    concepts like Java Array, Collections, Database
                    connectivity, Threads, Exception Handling, JSP, Servlets,
                    XML handling, etc. You will also learn various Java
                    frameworks like Hibernate and Spring.
                  </p>
                  <p>
                    Java is a high-level, class-based, object-oriented
                    programming language that is designed to have as few
                    implementation dependencies as possible.{" "}
                  </p>
                  <p>
                    It is a general-purpose programming language intended to let
                    programmers write once, run anywhere, meaning that compiled
                    Java code can run on all platforms that support Java without
                    the need to recompile.
                  </p>
                  <p>
                    Java applications are typically compiled to bytecode that
                    can run on any Java virtual machine (JVM) regardless of the
                    underlying computer architecture.{" "}
                  </p>
                  <p>
                    The syntax of Java is similar to C and C++, but has fewer
                    low-level facilities than either of them.
                  </p>
                  <p>
                    The Java runtime provides dynamic capabilities (such as
                    reflection and runtime code modification) that are typically
                    not available in traditional compiled languages
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img className="img-fluid" src={JavaImg} alt="First slide" />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    What are the objectives of our Java Certification Training?
                  </h3>
                  <p>
                    Our Java Certification Training is designed by industry
                    experts to make you a Certified Java Developer. Following
                    are the objectives our Java programming course offers:
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    In-depth knowledge of Java data types, conditional
                    statements and loops, multi-threading and exception
                    handling, a database using hibernate framework
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Comprehensive knowledge of object-oriented programming
                    through Java using Classes, Objects and various Java
                    concepts like Abstract, Final etc and parse XML files using
                    DOM and SAX
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    The capability to implement arrays, functions and string
                    handling techniques, JDBC to communicate with Database, SOA
                    using web services
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    The exposure to many real-life industry-based projects
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Projects which are diverse in nature covering banking,
                    telecommunication, social media, insurance, and e-commerce
                    domains
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Rigorous involvement of an SME throughout the Java Training
                    course to learn industry standards and best practices
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default Java;
