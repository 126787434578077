import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import AI1 from "../../assets/img/courses/ai.jpg";
import AI2 from "../../assets/img/courses/ai2.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class AI extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">
                Artificial Intelligence & Machine Learning
              </h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>
                    Artificial Intelligence & Machine Learning For Professionals{" "}
                  </h3>
                  <p>
                    Artificial Intelligence & Machine Learning for Professionals
                    is an immersive, application oriented, real-world
                    scenario-based
                  </p>
                  <p>
                    Programme in Artificial Intelligence & Machine Learning with
                    a flexibility to choose and master a specific
                    specialization.
                  </p>
                  <h3>Who this course is for?</h3>
                  <p>
                    This package is designed for any undergraduates or job
                    seekers and laterals to enhance their knowledge with BE/
                    BTech, B.Sc, BCA, MCA, BE (EEE), M.Sc.
                  </p>
                  <p>
                    Professionals working in the domains of analytics, Data
                    Science, search engine, etc.
                  </p>
                  <p>
                    Software professionals and new graduates seeking a career
                    change.
                  </p>
                  <p>
                    Fresher’s/ Undergraduates/ Graduates and MBA Graduates can
                    also apply
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img className="img-fluid" src={AI1} alt="First slide" />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto mb-4" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">Specializations</h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Machine Learning 
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Prediction with regression and time series
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Recommendation Engine
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Natural Language Processing
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Computer Vision
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Reinforcement Learning
                  </p>
                  <h3 className="text-white">Why take this course? </h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Today, Artificial Intelligence has conquered almost every
                    industry. Within a year or two, nearly 80% of emerging
                    technologies will be based on AI.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Machine Learning, especially Deep Learning, which is the
                    most important aspect of Artificial intelligence, is used
                    from AI-powered recommender systems (Chatbots) and Search
                    engines for online movie recommendations.
                  </p>
                </Animated>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1500}
                  animationInDuration={1500}
                  isVisible={true}
                >
                  <h3 className="text-white">Salient features</h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Focused approach
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Get real, Immersive
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    By industry for industry
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Network with best class
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Accelerate your Career
                  </p>
                  <h3 className="text-white">Post Programme Support</h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Client Project - Virtual internship
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Personalized Assessment Report
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Placement support
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Incubation
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Community Connect
                  </p>
                </Animated>
              </Col>
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1500}
                  animationInDuration={1500}
                  isVisible={true}
                >
                  <img className="img-fluid" src={AI2} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default AI;
