import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import Gototop from "../Common/Includes/Top";
import PlaceImg from "../../assets/img/interviewImg.jpg";
import { Animated } from "react-animated-css";

class Placement extends Component {
  /* constructor(props) {
        super(props);
    } */
  state = {};
  render() {
    return (
      <>
        <section className="innerBanner placementBg">
          <Container>
            <div>
              <h3 className="text-white text-center">Placement</h3>
            </div>
          </Container>
        </section>
        <section className="about-section">
          <div className="container">
            <div className="row">
              <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                <div className="inner-column">
                  <Animated
                    animationIn="fadeInRight"
                    animationInDelay={500}
                    animationInDuration={500}
                    isVisible={true}
                  >
                    <div className="sec-title">
                      <h2>Recruitment & Placement Services</h2>
                    </div>
                    <p>
                      We Tie-Up with 200+ Leading IT and Corporate Companies.
                    </p>
                    <p>We also do staffing for IT Industry </p>
                    <p>
                      Finding the right candidate for association can be an
                      incapacitating task.
                    </p>
                    <p>
                      But with GRABB,Hiring a dedicated and talented individual
                      from a pool of potential candidates is an easy task.{" "}
                    </p>
                    <p>
                      We have better expansion of the network than the human
                      resource department of the company. So, the possibility of
                      getting the top talent is high.
                    </p>
                    <p>
                      We have experience to deliver the best services you need
                      to move forward.
                    </p>
                    <p>
                      Get associated with us and avail the benefits of our
                      services.
                    </p>
                    <p>
                      We are engaged in recruiting of suitable candidates for
                      varied job opening in small as well as big corporates.
                    </p>
                    <p>
                      Our team clearly understands the requirements of our
                      clients since our team members have good expertise and
                      sound knowledge of their work. Our Motto is “Provide
                      Quality Service and Receive Customer Satisfaction”.
                    </p>
                  </Animated>
                </div>
              </div>

              <div className="image-column col-lg-6 col-md-12 col-sm-12">
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <div className="inner-column">
                    <figure className="image-1">
                      <a
                        href="#"
                        className="lightbox-image"
                        data-fancybox="images"
                      >
                        <img
                          src={PlaceImg}
                          className="img-fluid"
                          alt=""
                          title=""
                        />
                      </a>
                    </figure>
                  </div>
                </Animated>
              </div>
            </div>

            {/* <div className="sec-title">
              <span className="title">Our Future Goal</span>
              <h2>We want to lead in innovation & Technology</h2>
            </div> */}
            {/* <div className="text">
              We works on UI/UX and functionality as well so that a plugins
              comes with proper stucture & stunning looks which suits to your
              web app & website.
            </div>
            <div className="text">
              We take a small toolkit here and ride it well so that it is fit
              for your use. One who performs well and looks even better.
            </div>
            <div className="text">
              Here we are trying to give you all kinds of technical content,
              whether it is related to designing or functionality. We are
              creating content on a lot of languages and will continue to make
              it free of cost even if you use it without any problem. Which is a
              very important thing.
            </div>
            <div className="text">
              Here you can also share the content you create, if our technical
              team likes it, then we will also share it on our blog.
            </div>
            <div className="text">
              In the end, I would say keep visiting our website and enjoy the
              quality content.
            </div> */}
          </div>
        </section>

        {/*  <section className="placement">
          <Container>
            <Row>
              <Col className="img" sm={12} md={12} lg={6}></Col>
              <Col sm={12} md={12} lg={6}>
                <div className="textbg">
                  <h2>Recruitment & Placement Services </h2>
                  <p>We Tie-Up with 200+ Leading IT and Corporate Companies.</p>
                  <p>We also do staffing for IT Industry </p>
                  <p>
                    Finding the right candidate for association can be an
                    incapacitating task.
                  </p>
                  <p>
                    But with GRABB,Hiring a dedicated and talented individual
                    from a pool of potential candidates is an easy task.{" "}
                  </p>
                  <p>
                    We have better expansion of the network than the human
                    resource department of the company. So, the possibility of
                    getting the top talent is high.
                  </p>
                  <p>
                    We have experience to deliver the best services you need to
                    move forward.
                  </p>
                  <p>
                    Get associated with us and avail the benefits of our
                    services.
                  </p>
                  <p>
                    We are engaged in recruiting of suitable candidates for
                    varied job opening in small as well as big corporates.
                  </p>
                  <p>
                    Our team clearly understands the requirements of our clients
                    since our team members have good expertise and sound
                    knowledge of their work. Our Motto is “Provide Quality
                    Service and Receive Customer Satisfaction”.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}
        <Gototop />
      </>
    );
  }
}

export default Placement;
