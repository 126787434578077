import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";

import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import Gototop from "../Common/Includes/Top";
import { Animated } from "react-animated-css";

class Testimonial extends Component {
  /* constructor(props) {
        super(props);
    } */
  state = {};
  render() {
    return (
      <>
        <section className="innerBanner testimonialBg">
          <Container>
            <div>
              <h3 className="text-white text-center">Testimonials</h3>
            </div>
          </Container>
        </section>

        <section className="section_bg">
          <Container>
            <Row>
              <article className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <Animated
                  animationIn="fadeIn"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <div className="testimonialBox">
                    <p>
                      <RiDoubleQuotesL
                        size={40}
                        color={"#003768"}
                        className="me-2"
                      />
                      It was happy to share my experience with GRABB Private
                      Limited. I started my JAVA course in GRABB Private
                      Limited. Expert knowledge of JAVA expert with best and
                      friendly training. They give you the best support to build
                      the career goals with the placement guidance. Apart from
                      training there are much more activities that helps to
                      build your skills and handle the real time projects in
                      your workplace.
                    </p>

                    <div className="row">
                      <div className="col-3 col-sm-4 col-md-3 col-lg-3 col-xl-3 float-left pe-2 text-center">
                        <div className="testimonialCircle mx-auto">
                          <img
                            src="https://i.ibb.co/yfdd2fb/user.png"
                            className="imgNotAvailable"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-9 col-sm-8 float-left testimonialUser text-truncate ps-0">
                        <span className="mainHeading">Miss. Monisha</span>
                      </div>
                    </div>
                  </div>
                </Animated>

                <Animated
                  animationIn="fadeIn"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <div className="testimonialBox">
                    <p>
                      <RiDoubleQuotesL
                        size={40}
                        color={"#003768"}
                        className="me-2"
                      />
                      GRABB Private Limited guided me the first step towards
                      success and helped me to build a career in IT. Provided
                      flexible course timings, good study material. Friendly
                      environment, Great learning experience “Best teaching at
                      GRABB”
                    </p>

                    <div className="row">
                      <div className="col-3 col-sm-4 col-md-3 col-lg-3 col-xl-3 float-left pe-2 text-center">
                        <div className="testimonialCircle mx-auto">
                          <img
                            src="https://i.ibb.co/yfdd2fb/user.png"
                            className="imgNotAvailable"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-9 col-sm-8 float-left testimonialUser text-truncate ps-0">
                        <span className="mainHeading">Mr. Sadham Hussain</span>
                      </div>
                    </div>
                  </div>
                </Animated>

                <Animated
                  animationIn="fadeIn"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <div className="testimonialBox">
                    <p>
                      <RiDoubleQuotesL
                        size={40}
                        color={"#003768"}
                        className="me-2"
                      />
                      I am very thankful to all the teachers and placement team
                      of GRABB Private Limited to support us. GRABB also train
                      candidate from the interview point of view. They make
                      efforts for getting students placed in the best company.
                      It is great platform for freshers to enhance knowledge and
                      for better job opportunities. Thank you so much GRABB
                      Private Limited for everything.
                    </p>

                    <div className="row">
                      <div className="col-3 col-sm-4 col-md-3 col-lg-3 col-xl-3 float-left pe-2 text-center">
                        <div className="testimonialCircle mx-auto">
                          <img
                            src="https://i.ibb.co/yfdd2fb/user.png"
                            className="imgNotAvailable"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-9 col-sm-8 float-left testimonialUser text-truncate ps-0">
                        <span className="mainHeading">Miss. Dhanusya</span>
                      </div>
                    </div>
                  </div>
                </Animated>
              </article>

              <article className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <Animated
                  animationIn="fadeIn"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <div className="testimonialBox">
                    <p>
                      <RiDoubleQuotesL
                        size={40}
                        color={"#003768"}
                        className="me-2"
                      />
                      Before joining GRABB Private Limited my knowledge was
                      limited to my academic syllabus only. But now after
                      joining GRABB Private Limited my knowledge has elaborated.
                      I learnt how software is developed in real-time
                      environment. Also learnt how to face an interview
                      question. In my opinion my attitude has completely changed
                      towards IT industry after GRABB Private Limited.
                    </p>

                    <div className="row">
                      <div className="col-3 col-sm-4 col-md-3 col-lg-3 col-xl-3 float-left pe-2 text-center">
                        <div className="testimonialCircle mx-auto">
                          <img
                            src="https://i.ibb.co/yfdd2fb/user.png"
                            className="imgNotAvailable"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-9 col-sm-8 float-left testimonialUser text-truncate ps-0">
                        <span className="mainHeading">Mr. Sangameshwaran</span>
                      </div>
                    </div>
                  </div>
                </Animated>
                <Animated
                  animationIn="fadeIn"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <div className="testimonialBox">
                    <p>
                      <RiDoubleQuotesL
                        size={40}
                        color={"#003768"}
                        className="me-2"
                      />
                      I joined GRABB Private Limited for Java Script, Angular
                      and React JS. The explanation and understanding level were
                      of professional level. It really helped me in solving the
                      difficulties in my job.
                    </p>
                    <p>
                      Best trainer and placement team. I got placed through them
                      I would recommend the person who want to start their
                      career in IT field.
                    </p>
                    <p>Thanks GRABB Private Limited.</p>

                    <div className="row">
                      <div className="col-3 col-sm-4 col-md-3 col-lg-3 col-xl-3 float-left pe-2 text-center">
                        <div className="testimonialCircle mx-auto">
                          <img
                            src="https://i.ibb.co/yfdd2fb/user.png"
                            className="imgNotAvailable"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-9 col-sm-8 float-left testimonialUser text-truncate ps-0">
                        <span className="mainHeading">Mr. Raghu</span>
                      </div>
                    </div>
                  </div>
                </Animated>
              </article>

              <article className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <Animated
                  animationIn="fadeIn"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <div className="testimonialBox">
                    <p>
                      <RiDoubleQuotesL
                        size={40}
                        color={"#003768"}
                        className="me-2"
                      />
                      I just wanted to take a minute today to let you know what
                      a fantastic job you guys are doing in the module of
                      current affairs. Your newly launched segments of
                      beebooster and beepedia are really impressive. A kind
                      piece of note : Only one suggestion i would like make is
                      that kindly include ministry wise important section in
                      your beepedia which is exclusive for nabard, rbi and sebi.
                      As most questions in nabard were directly asked FOR
                      ministry. Eg- 1)which ministry has launched this scheme or
                      2) the scheme comes under which ministry. Thanking you for
                      your great efforts.
                    </p>

                    <div className="row">
                      <div className="col-3 col-sm-4 col-md-3 col-lg-3 col-xl-3 float-left pe-2 text-center">
                        <div className="testimonialCircle mx-auto">
                          <img
                            src="https://i.ibb.co/yfdd2fb/user.png"
                            className="imgNotAvailable"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-9 col-sm-8 float-left testimonialUser text-truncate ps-0">
                        <span className="mainHeading">Mr. Purushothaman</span>
                      </div>
                    </div>
                  </div>
                </Animated>
                <Animated
                  animationIn="fadeIn"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <div className="testimonialBox">
                    <p>
                      <RiDoubleQuotesL
                        size={40}
                        color={"#003768"}
                        className="me-2"
                      />
                      They helped me to enhance my knowledge of java and other
                      modules which were in my course. I have completed java
                      full stack course from here. Cooperative faculty and they
                      helps in every aspect they could regarding carrier and
                      lectures they are taking. I enjoyed my time with GRABB
                      Private Limited is the best in training and placement. All
                      staff are good and always ready to support. Good course
                      material. Placement team will support you till you get the
                      job. Go and get your own experience.
                    </p>
                    <p>GRABB Private Limited will make your career.</p>

                    <div className="row">
                      <div className="col-3 col-sm-4 col-md-3 col-lg-3 col-xl-3 float-left pe-2 text-center">
                        <div className="testimonialCircle mx-auto">
                          <img
                            src="https://i.ibb.co/yfdd2fb/user.png"
                            className="imgNotAvailable"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-9 col-sm-8 float-left testimonialUser text-truncate ps-0">
                        <span className="mainHeading">Mr. Deva</span>
                      </div>
                    </div>
                  </div>
                </Animated>
              </article>
            </Row>
          </Container>
        </section>

        <Gototop />
      </>
    );
  }
}

export default Testimonial;
