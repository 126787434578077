import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import Devops from "../../assets/img/courses/devops.png";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class DevOps extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">DevOps</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is DevOps?</h3>
                  <p>
                    This DevOps certification training will help you gain the
                    required skill set for getting a DevOps Engineer job.
                  </p>
                  <p>
                    This DevOps online course will make you proficient in DevOps
                    skills like Continuous Integration, Delivery and Deployment,
                    Infrastructure as a Code, and more using DevOps tools like
                    Git, SVN, Docker, Docker Swarm, Kubernetes, Puppet, Ansible,
                    Selenium, Maven, Nagios, Chef, etc.
                  </p>
                  <h3>What will you learn in this DevOps training?</h3>
                  <p>
                    In this online course, you will learn the following aspects:
                  </p>
                  <p>In-depth knowledge of DevOps methodology</p>
                  <p>Implementing Software Version Control</p>
                  <p>Containerizing Code on production using Docker</p>
                  <p>Creating CI/CD Pipelines using Jenkins</p>
                  <p>Configuration Management using Puppet and Ansible</p>
                  <p>Automating build and test using Selenium and Maven</p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img className="img-fluid" src={Devops} alt="First slide" />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    Who should take this DevOps certification course?
                  </h3>
                  <p>This DevOps certification can be taken up by:</p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Fresher’s/ Undergraduates/ Graduates and MBA Graduates can
                    also apply
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    IT Professionals
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    BE/ BTech, B.Sc, BCA, MCA, BE (EEE), M.Sc.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Software Testers
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    System Admins
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Application Developers
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default DevOps;
