import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import {
  FaHeadset,
  FaEnvelopeOpenText,
  FaMapMarkedAlt,
  FaMapMarked,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import * as Bi from "react-icons/bs";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import Gototop from "../Common/Includes/Top";
import { Animated } from "react-animated-css";

class Contact extends Component {
  /* constructor(props) {
        super(props);
    } */
  state = {};
  render() {
    return (
      <>
        <section className="innerBanner contactBg">
          <Container>
            <div>
              <h3 className="text-white text-center">Contact Us</h3>
            </div>
          </Container>
        </section>
        {/* <section>Contact</section> */}

        <section className="contact_us">
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <div className="contact_inner">
                  <Row>
                    <Col md={9}>
                      <div className="contact_form_inner">
                        <Animated
                          animationIn="fadeInUp"
                          animationInDelay={500}
                          animationInDuration={500}
                          isVisible={true}
                        >
                          <div className="contact_field">
                            <h3>Get In Touch</h3>
                            <p>
                              Feel Free to contact us any time. We will get back
                              to you as soon as we can!.
                            </p>
                            <input
                              type="text"
                              className="form-control form-group"
                              placeholder="Name"
                            />
                            <input
                              type="text"
                              className="form-control form-group"
                              placeholder="Email"
                            />
                            <textarea
                              className="form-control form-group"
                              placeholder="Message"
                            ></textarea>
                            <button className="contact_form_submit">
                              Submit
                            </button>
                          </div>
                        </Animated>
                      </div>
                    </Col>

                    <Col md={3} className="web_social_icon">
                      <div className="right_conatct_social_icon d-flex align-items-end">
                        <div className="socil_item_inner d-flex">
                          <li>
                            <a href="#">
                              <FaFacebookF />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <FaInstagram />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <FaTwitter />
                            </a>
                          </li>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="contact_info_sec">
                    <Animated
                      animationIn="fadeInUp"
                      animationInDelay={500}
                      animationInDuration={500}
                      isVisible={true}
                    >
                      <h4>Contact Info</h4>
                      {/* <div className="d-flex info_single align-items-center">
                        <FaHeadset />
                        <span>+(044) 4380 5522</span>
                      </div> */}
                      <div className="d-flex info_single align-items-center">
                        <FaEnvelopeOpenText />
                        <span>info@grabbtech.com</span>
                      </div>
                      <div className="d-flex info_single align-items-center">
                        <FaMapMarkedAlt className="width_90" />
                        <span>
                          TA1, Sree Ragaventhara Nestle, Kumbakonathan Salai,
                          Old Kallanai Road, Thiruvanakovil, Trichy-620 005.
                        </span>
                      </div>
                    </Animated>

                    <div className="mobile_social_icon">
                      <div className="right_conatct_social_icon d-flex align-items-end">
                        <div className="socil_item_inner d-flex">
                          <li>
                            <a href="#">
                              <FaFacebookF />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <FaInstagram />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <FaTwitter />
                            </a>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Gototop />
      </>
    );
  }
}

export default Contact;
