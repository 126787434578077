import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import BlockchainImg from "../../assets/img/courses/blockchain.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class Blockchain extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Blockchain</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>Blockchain</h3>
                  <p>Certificate Programme in Blockchain</p>
                  <p>There are four programmes in Blockchain</p>
                  <ol>
                    <li>Blockchian Certified Consultant</li>
                    <li>Blockchain Certified Developer</li>
                    <li>Blockchain Certified Expert </li>
                  </ol>
                  <h3>Who this course is for?</h3>
                  <p>
                    Management/Professionals/Domain Expert/Middle Tier
                    Management
                  </p>
                  <p>
                    Fresher’s/ Undergraduates/ Graduates and MBA Graduates can
                    also apply
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={BlockchainImg}
                    alt="First slide"
                  />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">Why take this course?</h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Who want to became Developer in Blockchain
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Working professionals who want to upgrade their skills
                  </p>
                  <h3 className="text-white">Programme Highlights</h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Internship and Placement Opportunity
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Practical & hands-on
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Industry mentor interaction 
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Hackathons
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Industry projects
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Case study based approach
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default Blockchain;
