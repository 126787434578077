import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import OracleCode from "../../assets/img/courses/oracle.png";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class Oracle extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Oracle</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Oracle?</h3>
                  <p>
                    Oracle is a product from Oracle Corporation that provides a
                    relational database management system. RDBMS supports any
                    kind of data model. It has different product editions,
                    including Standard Edition, Enterprise Edition, Express
                    Edition, and Personal Edition. Oracle products are scalable
                    and secure, with high-performance ability.
                  </p>
                  <p>
                    Oracle Database is a multi-model relational database
                    management system specially created for data warehousing and
                    computing the enterprise grid. The database software sits at
                    the centre of many corporate IT environments, supporting a
                    mix of transaction processing, business intelligence and
                    analytics applications.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={OracleCode}
                    alt="First slide"
                  />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto mb-4" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <p>
                    Its database currently comes in five different editions
                    based on the available features.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Standard Edition One: It is suitable for single-server or
                    highly branched business applications with limited features.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Standard Edition: It delivers all facilities provided in
                    Standard Edition One. In addition, it provides larger
                    machine support and Oracle Real Application clustering
                    service.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Enterprise Edition: This edition is packed with features
                    like security, performance, scalability, and availability,
                    required for highly-critical applications in which online
                    transaction processing is involved.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Express Edition: It is an entry-level edition that is free
                    to download, install, manage, develop and deploy.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Personal Edition: It comes with the same Enterprise edition
                    features except Oracle Real Application Clustering.
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default Oracle;
