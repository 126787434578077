import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import IOTImg from "../../assets/img/courses/iot.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class IOT extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">IoT – Internet of Things</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>
                    What is the Internet of Things, and why is it important to
                    learn about?
                  </h3>
                  <p>
                    The Internet of Things, abbreviated IoT, expands access to
                    the world-wide web from computers, smart phones, and other
                    typical devices to create a vast network of appliances,
                    toys, apparel, and other goods that are capable of
                    connecting to the internet. These goods, which range from
                    refrigerators to bicycles and everything in between, are
                    equipped with computer chips, microcontrollers, and sensors
                    that can collect and transmit data.
                  </p>
                  <p>
                    The IoT is important to study for learners interested in
                    enabling the development of IoT products, as well as in
                    gleaning the data generated by them.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img className="img-fluid" src={IOTImg} alt="First slide" />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    What skills or experience do I need to already have, Before
                    starting to learn IOT?
                  </h3>
                  <p>
                    The skills and experience that you might need to already
                    have before starting to learn IoT would likely include an
                    interest in engineering and embedded systems, a deep
                    understanding of the internet and how it’s transforming
                    society, and how devices are impacting businesses and
                    consumers because of the Internet of Things. Some of the
                    practical areas to learn might include C or C++ programming,
                    debugging, and API setups. In effect, IoT takes normal,
                    everyday objects and turns them into smart devices that can
                    do a number of things, like automate tasks, transmit data,
                    and emit sounds, without the person having to press buttons
                    or click manually on the device. Having the knowledge of how
                    software and hardware work together for these IoT devices
                    can help you in your internet-focused career.
                  </p>
                  <h3 className="text-white">
                    How do I know if learning IOT is right for me?
                  </h3>
                  <p>
                    You might know if learning IoT is right for you if you want
                    to gain a stronger knowledge about the latest advances in
                    technology, and how they are impacting our modern society.
                    Clearly, if you love health and fitness tracking wearable
                    devices or home voice assistants, and use all the gadgetry
                    and technology in your car and embedded tech in your kitchen
                    tools, then it would be a good guess that learning IoT is
                    right for you.
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default IOT;
