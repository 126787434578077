import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import DigitalMarket from "../../assets/img/courses/digitalmarketing.png";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class DigitalMarketing extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Digital marketing</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Digital marketing?</h3>
                  <p>
                    Digital marketing is the component of marketing that uses
                    the Internet and online based digital technologies such
                    as desktop computers, mobile phones and other digital
                    media and platforms to promote products and services.
                  </p>
                  <p>
                    This Digital Marketing courses category includes all the
                    training courses in the digital marketing arena. Digital
                    marketing is being deployed by every organization that wants
                    to garner the attention of its customers in the digital
                    world. Soon all marketing will be digital marketing thanks
                    to the ubiquitous nature of the digital marketing platforms
                    and the internet in general. Thus professionals trained in
                    digital marketing can expect to command a good salary.
                  </p>
                  <h3>Why learn Digital Marketing courses?</h3>
                  <p>
                    A digital marketing career provides space and multiple
                    options for newcomers and those who are willing to pursue a
                    career in digital marketing.{" "}
                  </p>
                  <p>
                    Skills like content writing, SEO writing, video making,
                    business communication, and many other skills are required
                    for a digital marketing career.
                  </p>
                  <p>
                    LinkedIn shows over 36,000 Digital Marketing job openings in
                    the United States and more than 10,000 jobs in India
                  </p>
                  <p>
                    Digital Marketers in the United States earn in the range of
                    US$61,027 to US$77,238 per annum – Indeed
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={DigitalMarket}
                    alt="First slide"
                  />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    Is Digital Marketing a good career?
                  </h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    In the world we live in today, most of the marketing process
                    is done over the Internet through various platforms, such as
                    websites, social media, etc.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Companies from all around the world have digitized their
                    systems, which has given rise to numerous job opportunities
                    in top organizations.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Besides, Digital Marketing is a vast field and provides a
                    platform for professionals with numerous skills.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Digital Marketing is one of the most sought-after career
                    opportunities as well.
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default DigitalMarketing;
