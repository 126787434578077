import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import SeleniumCode1 from "../../assets/img/courses/selenium.jpg";
import SeleniumCode2 from "../../assets/img/courses/selenium2.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class Selenium extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Selenium</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Selenium?</h3>
                  <p>
                    Selenium is an open source umbrella project for a range of
                    tools and libraries aimed at supporting browser automation.
                    It provides a playback tool for authoring functional tests
                    across most modern web browsers, without the need to learn a
                    test scripting language (Selenium IDE).
                  </p>
                  <p>
                    Selenium certification training course online helps you
                    learn automation testing and become a certified A4Q Selenium
                    Tester. As part of the online Selenium training course, you
                    will learn components such as Selenium IDE, RC, Selenium Web
                    Driver, and Selenium Grid through hands-on projects and case
                    studies. Learn from industry experts and SME’s become
                    certified with this Selenium course.
                  </p>
                  <h3>
                    What are the prerequisites for taking up this Selenium
                    Course?
                  </h3>
                  <p>
                    Having a basic knowledge of C or Java is beneficial but not
                    mandatory for taking up this Selenium certification
                    training.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={SeleniumCode1}
                    alt="First slide"
                  />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    How will Selenium help your career?
                  </h3>
                  <p>
                    Today, most companies prefer to use open-source automated
                    testing tools that will enable them to cut down on the cost
                    and effort that manual testing involves. Selenium is one
                    such open-source automated testing tool for websites. The
                    performance, execution speed, and browser interaction of
                    Selenium are faster than any other automation tool. The test
                    packages, the modes available for unattended execution, and
                    the ability to develop the test script in any language
                    supported by Selenium make it the most popular automation
                    testing tool. In addition, Selenium supports different
                    operating systems along with support for different
                    programming languages such as Python, Pearl, Ruby, PHP,
                    .NET, C# and Java, and various browsers, thereby making
                    Selenium the leading automation testing tool.
                  </p>
                </Animated>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1500}
                  animationInDuration={1500}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    Who should take up this Selenium Certification Course?
                  </h3>
                  <p>
                    The automation testing market is growing significantly
                    across the world, and such a strong growth pattern followed
                    by market demand is an excellent opportunity for the
                    following IT professionals.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Fresher’s
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Software Testers
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    QA Engineers
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    System Analysts
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Software Developers
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    System Administrators
                  </p>
                </Animated>
              </Col>
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1500}
                  animationInDuration={1500}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={SeleniumCode2}
                    alt="First slide"
                  />
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default Selenium;
