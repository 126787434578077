import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import AWSImg from "../../assets/img/courses/aws.png";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class AWS extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">AWS</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is AWS?</h3>
                  <p>
                    The full form of AWS is Amazon Web Services. It is a
                    platform that offers flexible, reliable, scalable,
                    easy-to-use and, cost-effective cloud computing solutions.
                  </p>
                  <p>
                    AWS is a comprehensive, easy to use computing platform
                    offered Amazon. The platform is developed with a combination
                    of infrastructure as a service (IaaS), platform as a service
                    (PaaS) and packaged software as a service (SaaS) offerings.
                  </p>
                  <h3>What is ASW?</h3>
                  <p>
                    Amazon Web Services is a leading cloud platform that offers
                    users over 200 comprehensive services from secure global
                    data centers. Its creators engineered this cloud platform
                    for high flexibility and security in the cloud computing
                    environment. The core infrastructure meets the rigorous
                    security requirements for global banks, the military, and
                    other organizations needing maximum protection.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img className="img-fluid" src={AWSImg} alt="First slide" />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    Who can learn the AWS Certification Online Course at GRABB?
                  </h3>
                  <p>
                    The AWS Certification Online Course at GRABB offers the
                    advanced level AWS Training ideal for professionals such as
                    IT Professionals, Software Developers, Software Testers,
                    Network Administrators, System Administrators, and DevOps
                    professionals.
                  </p>
                  <h3 className="text-white">
                    Does AWS require programming skills?
                  </h3>
                  <p>
                    Coding skills are not required to work with Amazon Web
                    Services. Users can easily perform most basic tasks without
                    having any coding background. Most new users utilize the AWS
                    UI to create their infrastructure rather than using coding
                    tools. The UI approach is the most streamlined way to use
                    AWS
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default AWS;
