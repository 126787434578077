import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import CybersecurityImg from "../../assets/img/courses/cybersecurity.jpeg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class CyberSecurity extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">CyberSecurity</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is CyberSecurity</h3>
                  <p>
                    Cyber security is the knowledge and practice of keeping
                    information safe on the internet. It can mean keeping your
                    personal information safe when you browse the internet and
                    visit your favorite websites and social media pages. It can
                    also mean companies keeping information safe for their
                    customers when entering personal details or purchasing items
                    on their websites. And it can mean penetration testing and
                    network security analysis to make sure the most valuable
                    types of information stay safe. Cyber Security aims to keep
                    clients and their email and files safe from hackers.
                    Analysts can help clients finding and improving weak spots
                    in their security systems. Cyber security covers the gamut
                    from offering better password protection to keeping nuclear
                    codes safe.
                  </p>
                  <h3>Programme Highlights</h3>
                  <p>Trainer lead</p>
                  <p>Hackathons</p>
                  <p>Placement oriented</p>
                  <p>Experiential learning</p>
                  <p>Industry practitioners as mentor</p>
                  <p>Hands-on Labs sessions on Industry standard</p>
                  <p>Personalization Assessment Report</p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={CybersecurityImg}
                    alt="First slide"
                  />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">Benefits</h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    We have a Hackathons here
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Placement support
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Learn from industry leaders
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Peer network across top-notch companies
                  </p>
                  <h3 className="text-white">Tools you will learn</h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Burpsuite
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Wireshark
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    SQLMap
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    ReconNG
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Postman
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Nmap
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    bwApp
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Wayback Machine
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default CyberSecurity;
