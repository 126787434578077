import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import Powerbi from "../../assets/img/courses/powerbi.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class PowerBI extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Power BI</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Power BI?</h3>
                  <p>
                    Power BI provides cloud-based BI (business intelligence)
                    services, known as "Power BI Services", along with a
                    desktop-based interface, called "Power BI Desktop". It
                    offers data warehouse capabilities including data
                    preparation, data discovery, and interactive dashboards.
                  </p>
                  <p>
                    In March 2016, Microsoft released an additional service
                    called Power BI Embedded on its Azure cloud platform. One
                    main differentiator of the product is the ability to load
                    custom visualizations.
                  </p>
                  <h3>What is meant by Power BI?</h3>
                  <p>Power BI is a business analytics service by Microsoft.</p>
                  <p>
                    It is used to pull data from a wide range of systems in
                    cloud.
                  </p>
                  <p>
                    Power BI is a collection of software services, apps and
                    connectors.
                  </p>
                  <p>
                    Power BI is a very powerful in terms of tables, reports or
                    data files.
                  </p>
                  <p>
                    Power Bi dashboards are visually appealing, interactive and
                    customizable.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img className="img-fluid" src={Powerbi} alt="First slide" />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">Who Can Do This Course?</h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Power BI Training can be done by Fresher’s, Job Seekers and
                    Working Professionals
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    No Prerequisite for Power BI Training
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    The course is strategically designed to suffice the
                    requirement of current industry requirements
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Fresher’s will find the course easy to understand as every
                    inch of basic knowledge is covered in it
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Experience people who are willing to learn new technologies
                    will find the course very interesting as all topics are
                    elaborated with live examples and real time assignments
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default PowerBI;
