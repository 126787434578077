import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import Fullstack1 from "../../assets/img/courses/fullstack.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class Fullstack extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Full-stack Development</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Full-stack Development?</h3>
                  <p>
                    A full-stack developer is a type of programmer that has a
                    functional knowledge of all techniques,
                    languages and systems engineering concepts required
                    in software development. The term “full stack” refers to the
                    technologies and skills needed to complete a project, with
                    each individual component being a stack. Stacks can be
                    mobile, Web or Software specific.
                  </p>
                  <h3>Full Stack Developer Course Overview</h3>
                  <p>
                    This Full Stack Developer Program in India is designed to
                    give you an essence of front-end, middleware, and back-end
                    Java web developer technologies. You will learn to build an
                    end-to-end application, test and deploy code, store data
                    using MongoDB, and much more.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={Fullstack1}
                    alt="First slide"
                  />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    Skills of a full-stack developer
                  </h3>
                  <p>
                    Full-stack developers possess skills related to all layers,
                    or stages, involved in application or web development. This
                    includes:
                  </p>
                  <p>
                    Proficiency in multiple popular programming languages such
                    as:
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    JavaScript
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Java
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    PHP
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    C#
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Python
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Ruby
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Perl
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default Fullstack;
