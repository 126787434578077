import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import Datascience from "../../assets/img/courses/datascience.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class DataScience extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Data Science </h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Data Science ?</h3>
                  <p>
                    Our Data Science certification courses aim to accelerate
                    your Data Science career by making you proficient in this
                    domain. We aim to make you proficient in this field by
                    helping you learn both basic and advanced concepts of Data
                    Science, along with getting exposure to programming
                    languages and technologies including Python, R, Hadoop,
                    Tableau, and Spark. Besides, in these courses, you will gain
                    hands-on experience working on real-time exercises and
                    projects that will substantiate your learning.
                  </p>
                  <h3>What is Data Science?</h3>
                  <p>
                    Data Science includes both structured and unstructured data.
                    Besides, the algorithms of Data Science comprise predictive
                    analytics, while dealing with both past and future data. In
                    other words, it allows professionals to find valuable
                    information from historical data that further be utilized to
                    make present decisions. In turn, the patterns found in the
                    current data can be modeled and used for future predictions
                    of the data.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={Datascience}
                    alt="First slide"
                  />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">Why Learn Data science?</h3>
                  <p>
                    There is a massive amount of data that is generated in
                    organizations on a daily basis which has led to them
                    requiring Data Science and Analytics. In order to make the
                    most of the data that is regulated in companies from almost
                    every industry such as Marketing, Retail, Finance, Banking,
                    and IT, which is why Data Scientists and other Data Science
                    professionals are highly in-demand with high compensations.
                  </p>
                  <h3 className="text-white">
                    Who should take this Data Science certification course?
                  </h3>
                  <p>
                    Data Science job profiles require an amalgam of knowledge in
                    this domain as well as experienced in the necessary tools
                    and technologies. It is a great career option for beginners
                    and experienced professionals. Individuals and professionals
                    aspiring to become Data Scientists can sign up for this
                    course
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    IT Professionals
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Business Analysts
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Analytics Managers
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Supply Chain Network Managers
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Marketing Managers
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Finance and Banking Professionals
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default DataScience;
