import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import Rprogram from "../../assets/img/courses/rprogram.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class RProgramming extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">R Programming</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is R Programming?</h3>
                  <p>
                    R is a programming language for statistical computing and
                    graphics supported by the R Core Team and the R Foundation
                    for Statistical Computing. Created by statisticians Ross
                    Ihaka and Robert Gentleman, R is used among data miners,
                    bioinformaticians and statisticians for data analysis and
                    developing statistical software. Users have created packages
                    to augment the functions of the R language.
                  </p>
                  <h3>About this Course</h3>
                  <p>
                    In this course you will learn how to program in R and how to
                    use R for effective data analysis. You will learn how to
                    install and configure software necessary for a statistical
                    programming environment and describe generic programming
                    language concepts as they are implemented in a high-level
                    statistical language. The course covers practical issues in
                    statistical computing which includes programming in R,
                    reading data into R, accessing R packages, writing R
                    functions, debugging, profiling R code, and organizing and
                    commenting R code. Topics in statistical data analysis will
                    provide working examples.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img className="img-fluid" src={Rprogram} alt="First slide" />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">What you will learn?</h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Understand critical programming language concepts
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Configure statistical programming software
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Make use of R loop functions and debugging tools
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Collect detailed information using R profiler
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default RProgramming;
