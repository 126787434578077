import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import Inform1 from "../../assets/img/courses/inform.jpg";
import Inform2 from "../../assets/img/courses/inform2.png";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class InformicaTraining extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Informatica Online Training</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Informatica Online Training?</h3>
                  <p>
                    Our Informatica certification online helps you be a master
                    in the PowerCenter ETL tool, data integration, and Data
                    Warehousing. This Informatica training course will help you
                    clear the Informatica PowerCenter Developer Certification
                    exam. Master advanced concepts such as installation,
                    configuration, architecture, performance tuning, etc.
                  </p>
                  <h3>Why should I learn Informatica from GRABB?</h3>
                  <p>
                    Informatica is clearly a market leader in the fields of data
                    integration and data quality services. Informatica
                    PowerCenter is a powerful tool to integrate, manage, and
                    migrate enterprise data. Hence, taking up this Informatica
                    PowerCenter certification course from GRABB will definitely
                    upgrade your career.
                  </p>
                  <p>
                    Informatica certification training course is completely in
                    line with clearing the PowerCenter Developer Certification
                    exam. We provide hands-on, fast-paced training with
                    real-world projects and assignments that are designed
                    keeping in mind the industry needs.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img className="img-fluid" src={Inform1} alt="First slide" />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    What are the objectives of our Informatica Online Training?
                  </h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    In-depth knowledge of the Informatica PowerCenter, its
                    Architecture, and Environment
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Comprehensive knowledge of various tools like Informatica
                    Administrator Suite, Repository Manager, Designer, and
                    Workflow Manager
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Capability to load Dimension Tables, use Advanced
                    Transformation, recognize and repair Connection & Network
                    Errors, configure Workflows & Sessions, create Users and
                    Group and design Error Handling strategies
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Hands-on knowledge of Informatica PowerCenter command-line
                    tools
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    The exposure to many real-life industry-based Business
                    Intelligence Projects
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Rigorous involvement of an SME throughout the Informatica
                    Training to learn industry standards and best practices
                  </p>
                </Animated>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1500}
                  animationInDuration={1500}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    Who should go for this Informatica Training Online?
                  </h3>
                  <p>
                    Informatica is a highly favoured tool in the ETL and Data
                    Integration market. Informatica Learning opens up lucrative
                    and various growth opportunities. Our Informatica
                    Certification Course in GRABB will help you to grab this
                    opportunity and accelerate your career. It is best suited
                    for:
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Data Integration developers
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Informatica PowerCenter Administrators
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    BI/ETL/DW Professionals
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Administrators with some experience managing either
                    PowerCenter or Data Quality
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Individual Contributors in the field of Enterprise Business
                    Intelligence
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Database developers and developer Managers new to
                    Informatica PowerCenter
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Informatica Administrators with some considerable experience
                    in using PowerCenter or maintaining Data Quality
                  </p>
                </Animated>
              </Col>
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="img-fluid" src={Inform2} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default InformicaTraining;
