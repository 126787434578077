import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import Webdesign from "../../assets/img/courses/webdesign.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class WebDesigning extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Web Designing</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Web Designing?</h3>
                  <p>
                    Designing a website is all about creating web pages for
                    running online businesses that help in building a connection
                    between the business and the customers in a way to interact
                    as well as entertain. It is being produced to meet and solve
                    the daily challenges faced in promoting products and
                    services. And, our web design training course will help you
                    get real-time learning on live projects that help you become
                    an expert web designer.
                  </p>
                  <h3>What does a Web Designer do?</h3>
                  <p>
                    The role of a web designer will be to create a responsive
                    website and its layout using applications, tools and
                    integrate text, graphics, animation and much more to provide
                    an aesthetic feel.{" "}
                  </p>
                  <p>
                    Creating a site that meets the client’s exact vision with
                    good designing principles will be the most challenging task.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={Webdesign}
                    alt="First slide"
                  />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    Why learn Web Design Training from GRABB Private Limited
                  </h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Features in working with Photoshop, Dreamweaver, HTML, CSS,
                    JavaScript, jQuery, etc.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    We always have an updated modules and subjects that meet the
                    latest requirement of the IT field.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Our web design training syllabus is perfect for all those
                    who wants to learn from the scratch and get a web designing
                    certification to get into an IT job.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    The web designing course fees is tailor-made for batch
                    training and individual training which are based on unique
                    requirements of the candidates for weekday, weekend or fast
                    track batches.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    So, if you are looking for the best web designing training
                    along with assured job placement, it’s time to join our
                    GRABB.
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default WebDesigning;
