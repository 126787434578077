import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import Softwaretesting1 from "../../assets/img/courses/softwaretesting.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class SoftwareTesting extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Software Testing</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Software Testing?</h3>
                  <p>
                    Software testing is the act of examining the artifacts and
                    the behaviour of the software under test by validation and
                    verification. Software testing can also provide an
                    objective, independent view of the software to allow the
                    business to appreciate and understand the risks of software
                    implementation. Test techniques include, but not necessarily
                    limited to:
                  </p>
                  <p>
                    Analyzing the product requirements for completeness and
                    correctness in various contexts like industry perspective,
                    business perspective, feasibility and viability of
                    implementation, usability, performance, security,
                    infrastructure considerations, etc.
                  </p>
                  <p>
                    Reviewing the product architecture and the overall design of
                    the product
                  </p>
                  <p>
                    Working with product developers on improvement in coding
                    techniques, design patterns, tests that can be written as
                    part of code based on various techniques like boundary
                    conditions, etc.
                  </p>
                  <h3>Who this course is for?</h3>
                  <p>Fresher’s/Graduates/ Software Testers</p>
                  <p>
                    Any Software engineer who are interested in Mobile
                    Technologies
                  </p>
                  <p>
                    BE/ BTech, B.Sc, BCA, MCA, M.Sc and other technical
                    graduations.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={Softwaretesting1}
                    alt="First slide"
                  />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    Learn Software Testing with Certification & Placements.
                  </h3>
                  <p>
                    In this Course you will understand software testing from
                    basics to advance level.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    You will understand on how to test any application from the
                    scratch.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    You will also understand how agile works.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Software testing course is also designed for automating any
                    web application or project from scratch with in-depth
                    knowledge in Java & Selenium tool.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    You will understand the complete life cycle of software
                    testing with 2 projects on manual, Database and automation.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Successful completion of the programme earns a certificate
                    by MNC and Placement support
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default SoftwareTesting;
