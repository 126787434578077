import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import PythonCode1 from "../../assets/img/courses/python.jpg";
import PythonCode2 from "../../assets/img/courses/codePython.jpg";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class Python extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Python</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Python?</h3>
                  <p>
                    Python is a high-level, general-purpose programming
                    language. Its design philosophy emphasizes code readability
                    with the use of significant indentation. Python is
                    dynamically-typed and garbage-collected. It supports
                    multiple programming paradigms, including structured
                    (particularly procedural), object-oriented and functional
                    programming. It is often described as a "batteries included"
                    language due to its comprehensive standard library.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={PythonCode1}
                    alt="First slide"
                  />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto mb-4" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Python is Easy to Learn and Use
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Python can be used in ML tool
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Python Automates Tasks
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Highly compact and manageable
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Works on different platforms
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Python Developer's are in high demand. Learn Python
                    Programming Language from Beginner to Advanced level
                    exclusively designed with practical, real-time Scenarios for
                    meeting the purpose of the Industry Demands.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Best Course Content with hands-on Python Course in Chennai
                    for Assured Placements.
                  </p>
                </Animated>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1500}
                  animationInDuration={1500}
                  isVisible={true}
                >
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Learn how to use Python, from beginner basics to advanced
                    techniques, with real time projects taught by industry
                    experts.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Build your Python Programming Skills with Training and
                    Certification.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Build Your Developer Career on Python that lead to top jobs.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Develop advanced Python scripting skills.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Learn to leverage next-level Python features such as
                    object-oriented special class methods.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Fast Track classes available with best Python course Fees
                    with Best course content designed by Experts.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Our selenium with Python Training course in Chennai also
                    includes job placement assistance.
                  </p>
                </Animated>
              </Col>
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1500}
                  animationInDuration={1500}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={PythonCode2}
                    alt="First slide"
                  />
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default Python;
