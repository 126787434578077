import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import CloudComp from "../../assets/img/courses/cloudcomputing.png";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class CloudComputing extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Cloud Computing</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Cloud Computing?</h3>
                  <p>
                    Cloud computing is the on-demand availability of computer
                    system resources, especially data storage (cloud storage)
                    and computing power, without direct active management by the
                    user. Large clouds often have functions distributed over
                    multiple locations, each of which is a data center. Cloud
                    computing relies on sharing of resources to achieve
                    coherence and typically uses a "pay as you go" model, which
                    can help in reducing capital expenses but may also lead to
                    unexpected operating expenses for users.
                  </p>
                  <h3>Why learn Cloud Computing courses?</h3>
                  <p>
                    Cloud Computing is one of the most popular technologies
                    today. Let’s read on some important benefits of this
                    technology to opt for the best Cloud Computing training:
                  </p>
                  <p>Migration flexibility</p>
                  <p>Great performance</p>
                  <p>Resilience in workload</p>
                  <p>Increased agility and speed</p>
                  <p>Follows the pay-as-you-use feature</p>
                  <p>Provides great elasticity</p>
                  <p>Reduces company expenditures</p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={CloudComp}
                    alt="First slide"
                  />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">
                    What are the benefits of cloud computing?
                  </h3>
                  <p>
                    The following are few of the many benefits of cloud
                    computing.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Security: By entrusting your data with a cloud security
                    expert, you can protect your data from risks such as data
                    theft, be it internal or external.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Mobility: By uploading your data to the cloud, you can
                    access the same at anytime, anywhere.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Collaboration: By synchronizing data in the cloud, you can
                    collaborate with people in your organization and across the
                    borders as well.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Loss prevention: At times, data can be lost from your
                    computer due to various reasons. By backing up the data via
                    a cloud computing system, you will be able to access your
                    data through other devices as well.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Automatic software updates: Your or your organizations’
                    device will be updated automatically on implementing the
                    cloud computing service. This means that you can save a lot
                    of time that was spent in updating the devices manually.
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default CloudComputing;
