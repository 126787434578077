import React, { Component } from "react";
import { useState, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../../assets/img/logo-sw.png";
import Home from "../../Pages/Home";
import About from "../../Pages/About";
import FeaturedCourse from "../../Pages/FeaturedCourse";
import TransitionCourse from "../../Pages/TransitionCourse";
import OnlineCourse from "../../Pages/OnlineCourse";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Form,
} from "react-bootstrap";
import $ from "jquery";

$(window).scroll(function () {
  var scroll = $(window).scrollTop();

  if (scroll >= 30) {
    $("#header").addClass("header-scrolled");
  } else {
    $("#header").removeClass("header-scrolled");
  }
});
// const handleClick = (event) => {
//   event.currentTarget.classList.add("active");
// };

console.log(process.env.PUBLIC_URL);

class Header extends Component {
  render() {
    return (
      <header>
        <Navbar
          className="nav-bg menu"
          collapseOnSelect
          expand="lg"
          id="header"
        >
          <Container>
            <Navbar.Brand>
              <img
                src={logo}
                className="d-inline-block align-top logo"
                alt="logo"
                title="Grabbtech"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto menu-link">
                <NavLink
                  className="link nav-link"
                  to={`${process.env.PUBLIC_URL}/`}
                >
                  Home
                </NavLink>
                <NavLink
                  className="link nav-link"
                  to={`${process.env.PUBLIC_URL}/about`}
                >
                  About
                </NavLink>

                <NavDropdown
                  className="droplink"
                  title="Software Courses"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item className="link nav-link">
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/featuredcourse`}
                      style={{ color: "#002b46" }}
                    >
                      Featured Software Courses
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="link nav-link">
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/transitioncourse`}
                      style={{ color: "#002b46" }}
                    >
                      Career Transition Courses
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="link nav-link">
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/onlinecourse`}
                      style={{ color: "#002b46" }}
                    >
                      Online Courses
                    </NavLink>
                  </NavDropdown.Item>
                </NavDropdown>

                <NavLink
                  className="link nav-link"
                  to={`${process.env.PUBLIC_URL}/placement`}
                >
                  Placement
                </NavLink>
                <NavLink
                  className="link nav-link"
                  to={`${process.env.PUBLIC_URL}/testimonial`}
                >
                  Testimonials
                </NavLink>
                <NavLink
                  className="link nav-link"
                  to={`${process.env.PUBLIC_URL}/contact`}
                >
                  Contact Us
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default Header;
