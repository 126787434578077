import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import CProgram from "../../assets/img/courses/cprogram.png";
import * as Bi from "react-icons/bs";
import Individual from "../../assets/img/courseImgC.jpg";
import MicrosoftAzureImg from "../../assets/img/courses/microsoftazure.png";
import Gototop from "../Common/Includes/Top";
import AboutImg from "../../assets/img/about.jpg";
import { Animated } from "react-animated-css";

class MicrosoftAzure extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="clangBanner banner">
          <Container>
            <div className="text-center">
              <h3 className="text-white">Microsoft Azure</h3>
            </div>
          </Container>
        </section>
        <section className="aboutP">
          <Col className="explain mx-auto p-4" sm={12} md={10}>
            <Row>
              <Col className="my-auto  cornerL" sm={12} md={12} lg={7}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <h3>What is Microsoft Azure?</h3>
                  <p>
                    Microsoft Azure, often referred to as Azure is a cloud
                    computing platform operated by Microsoft for application
                    management via Microsoft-managed data centres.
                  </p>
                  <p>
                    Microsoft Azure has multiple capabilities such as software
                    as a service (SaaS), platform as a service (PaaS) and
                    infrastructure as a service (IaaS) and supports many
                    different programming languages, tools, and frameworks,
                    including both Microsoft-specific and third-party software
                    and systems.
                  </p>
                  <h3>Who should take this Azure certification course?</h3>
                  <p>
                    This Microsoft certified azure certification course caters
                    to those who are hoping to enter the cloud industry. The
                    following roles and disciplines are ideal for this Azure
                    course:
                  </p>
                  <p>
                    Fresh graduates who are intent on taking the plunge into the
                    job market
                  </p>
                  <p>
                    Professionals with less than 2 years of experience who are
                    working in either technical or non-technical job roles
                  </p>
                  <p>
                    Whether you’re new to the field or a seasoned professional,
                    mastering the basics in Microsoft Azure can help you
                    jump-start your career and prepare you to dive deeper into
                    the many technical opportunities Azure offers.
                  </p>
                  <p>
                    The certification validates your basic knowledge of cloud
                    services and how those services are provided with Azure.
                    Candidates should be able to demonstrate a fundamental
                    knowledge of cloud concepts, along with Azure services,
                    workloads, security, privacy, pricing, and support.
                  </p>
                </Animated>
              </Col>
              <Col
                className="text-center fluid my-auto cornerR"
                sm={12}
                md={12}
                lg={5}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={500}
                  animationInDuration={500}
                  isVisible={true}
                >
                  <img className="fluid" src={AboutImg} alt="First slide" />
                </Animated>
              </Col>
            </Row>
          </Col>
        </section>
        <section className="individualCourse aboutP">
          <div className="overlay"></div>
          <Container>
            <div className="explain mb-4 text-start"></div>
            <Row className="mb-4">
              <Col className="my-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInLeft"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <img
                    className="img-fluid"
                    src={MicrosoftAzureImg}
                    alt="First slide"
                  />
                </Animated>
              </Col>
              <Col className="my-auto mx-auto" sm={12} md={12} lg={6}>
                <Animated
                  animationIn="fadeInRight"
                  animationInDelay={1000}
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <h3 className="text-white">What are the course Objective?</h3>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    This Microsoft certified azure training program, you will
                    understand the main principles of cloud computing and how
                    they have been implemented in Microsoft Azure.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    This Azure training will make you an expert in Azure
                    applications and architecture.
                  </p>
                  <p>
                    <Bi.BsCheck className="checkIcon" />
                    Additionally, you will learn how to manage subscriptions,
                    billing, and role-based access control regarding Azure users
                    and groups; implement use cases and configuration options
                    for Azure app services and app service environments; design
                    an Azure app service web app
                  </p>
                </Animated>
              </Col>
            </Row>
          </Container>
        </section>
        <Gototop />
      </>
    );
  }
}

export default MicrosoftAzure;
